import type { Route } from '@bugbug/core/types/routes';

interface MixpanelPageConfiguration {
  name: string; // Name of page
  anonymous?: boolean; // If true, the page view event will not be associated with a user
}
export const mixpanelPageConfiguration: Partial<Record<Route, MixpanelPageConfiguration>> = {
  login: { name: 'Login Page' },
  signUp: { name: 'Sign Up Page' },
  signUpSuccess: { name: 'Sign Up Success Page' },
  welcome: { name: 'Welcome Onboarding Page' },
  setupProfile: { name: 'Profile Onboarding Page' },
  setupOrganization: { name: 'Organization Onboarding Page' },
  invoices: { name: 'Invoices Page' },
  creditCard: { name: 'Credit Card Page' },
  subscription: { name: 'Subscription Page' },
  billingAddress: { name: 'Billings Details Page' },
  users: { name: 'Organization Users Page' },
  organizationGeneral: { name: 'Organization Settings Page' },
  projectsList: { name: 'All Projects Page' },
  integrations: { name: 'Project Integrations Page' },
  componentsList: { name: 'Components Page' },
  profiles: { name: 'Variable Profiles Page' },
  customVariables: { name: 'Custom Variables Page' },
  builtInVariables: { name: 'Built-In Variables Page' },
  alerts: { name: 'Alerts Page' },
  schedulesList: { name: 'Schedules Page' },
  testRunsList: { name: 'Runs Tests History Page' },
  suitesList: { name: 'All Suites Page' },
  testsList: { name: 'All Tests Page' },
  projectGeneralSettings: { name: 'Project Settings Page' },
  projectBrowser: { name: 'Project Browser Page' },
  projectScreenSizes: { name: 'Project Screen Size Page' },
  projectSelectors: { name: 'Project Selectors Page' },
  projectWaitingConditions: { name: 'Project Conditions Page' },
  accountGeneral: { name: 'Account Settings Page' },
  accountChangePassword: { name: 'Change Password Page' },
  accountNotifications: { name: 'Account Notifications Page' },
  signUpByInvitation: { name: 'Invitation Page', anonymous: true },
  checkoutBillingAddress: { name: 'Upgrade 1 Step Page' },
  checkoutPayment: { name: 'Upgrade 2 Step Page' },
  checkoutConfirmation: { name: 'Upgrade 1 Step Page' },
  projectsNew: { name: 'Creation Project Page' },
  newTest: { name: 'Creation Test Page' },
};

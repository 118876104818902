import { useTranslation } from 'react-i18next';

import FormField from '~/components/FormField';

import * as S from './TeamsConfig.styled';

export const FinishStep = () => {
  const { t } = useTranslation();

  return (
    <FormField
      counter="3"
      label={t('integrationProcess.teams.config.finish.label', 'Save your configuration')}
    >
      <S.Step>
        {t(
          'integrationProcess.teams.config.finish.description',
          'Submit your configuration using the button in top right corner and then back to the BugBug.',
        )}
      </S.Step>
    </FormField>
  );
};

import ButtonBase from '@bugbug/core/components/Button';
import { COLOR } from '@bugbug/core/theme/colors';
import styled from 'styled-components';

import Card from '~/components/Card';

export const Container = styled.div`
  width: 100%;
  margin-top: 14px;
  display: grid;
  justify-content: space-between;
  grid-template-columns: auto auto;
  column-gap: 12px;
  justify-content: stretch;
`;

export const SocialLoginLogo = styled.img`
  height: 22px;
  margin: 0 auto;
`;

export const GoogleLogo = styled(SocialLoginLogo)`
  transform: translateY(2px);
`;

export const ButtonContainer = styled(Card)`
  padding: 0;
  overflow: hidden;
`;

export const Button = styled(ButtonBase)`
  height: 50px;
  border: none;
  background-color: ${COLOR.WHITE};
  border-radius: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
`;

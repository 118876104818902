import Link from '@bugbug/core/components/Link';
import React, { useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMount } from 'react-use';

import Card from '~/components/Card';
import useActionState from '~/hooks/useActionState';
import { UserActions } from '~/modules/user/user.redux';
import analytics, { TRACK_EVENT_TYPE } from '~/services/analytics';
import urls from '~/views/urls';

import { Container, Box, Title, Icon, Text, Email } from './SignUpSuccess.styled';

const SignUpSuccess = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const { email } = history?.location?.state || {};

  useMount(() => {
    analytics.trackEvent(TRACK_EVENT_TYPE.NOT_CONFIRM_SIGN_UP, { provider: 'regular' });
  });

  useEffect(() => {
    if (!history?.location?.state?.redirected) {
      history.push(urls.signUp);
    }
  }, [history]);

  const handleResendSuccess = useCallback(() => {
    history.push(urls.signUpSuccess, { email, redirected: true });
  }, [email, history]);

  useActionState(UserActions.resendActivationEmailRequest, {
    onSuccess: handleResendSuccess,
  });

  const handleResendEmail = useCallback(() => {
    dispatch(UserActions.resendActivationEmailRequest(email, true));
  }, [email, dispatch]);

  return (
    <Container>
      <Helmet>
        <title>{t('signUpSuccess.pageTitle', 'Account created!')}</title>
        <meta name="robots" content="none" />
      </Helmet>
      <Box>
        <Card>
          <Title>{t('signUpSuccess.title', 'Verify your e-mail address')}</Title>
          <Icon />
          <Text>
            <Trans i18nKey="signUpSuccess.description">
              We've sent a verification email to <Email>{{ email }}</Email>. Please check your email
              and click the link to activate your account.
            </Trans>
          </Text>
          <Text>
            <Trans>
              Didn't get the e-mail? <Link onClick={handleResendEmail}>Send again</Link>
            </Trans>
          </Text>
        </Card>
      </Box>
    </Container>
  );
};

export default SignUpSuccess;

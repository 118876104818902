import { ErrorMessage } from '@bugbug/core/theme/typography';
import { isGenericIntegrationAuthRequired } from '@bugbug/core/types/integrations';
import { renderWhenTrueOtherwise } from '@bugbug/core/utils/rendering';
import * as T from '@bugbug/core/utils/toolbox';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMount } from 'react-use';

import type { Integration } from '@bugbug/core/types/integrations';
import { INTEGRATION_LOGO } from '~/constants/integrations';
import useAppRoutes from '~/hooks/useAppRoutes';
import useQueryString from '~/hooks/useQueryString';
import { useConnectMutation } from '~/modules/integrations';
import { useAppDispatch, useAppSelector } from '~/modules/store';
import { UIStateActions, selectIntegrationTrigger } from '~/modules/uiState';
import { getExtractedErrorMessages } from '~/services/api/utils';

import * as S from './IntegrationAuthRedirect.styled';

interface IntegrationAuthRedirectProps {
  type: Integration['type'];
}

export const IntegrationAuthRedirect = ({ type }: IntegrationAuthRedirectProps) => {
  const { t } = useTranslation();
  const { replace } = useAppRoutes('integrationAuthRedirect');
  const queryParams = useQueryString();
  const [connect, { error, isSuccess }] = useConnectMutation();
  const integrationTrigger = useAppSelector(selectIntegrationTrigger);
  const dispatch = useAppDispatch();

  useMount(() => {
    if (
      queryParams.error ||
      !isGenericIntegrationAuthRequired(type) ||
      !integrationTrigger?.params
    ) {
      return;
    }

    connect({
      type,
      projectId: integrationTrigger.params.projectId!,
      code: queryParams.code ?? '',
      state: queryParams.state ?? '',
    });
  });

  const errors = getExtractedErrorMessages(error);

  const handleBack = useCallback(() => {
    if (integrationTrigger) {
      replace(
        integrationTrigger.routeName,
        integrationTrigger.params,
        integrationTrigger.queryParams,
        { connected: isSuccess },
      );
      dispatch(UIStateActions.removeIntegrationTrigger());
    } else {
      replace('integration', { type });
    }
  }, [integrationTrigger, replace, isSuccess, dispatch, type]);

  const renderContent = renderWhenTrueOtherwise(
    () => (
      <S.LoaderContainer>
        <S.Loader />
        {t('integrationProcess.auth.connecting', 'Connecting...')}
      </S.LoaderContainer>
    ),
    () => (
      <>
        {queryParams.error ? (
          <ErrorMessage>
            {t('integrationProcess.auth.accessDenied', 'Integration process cancelled')}
          </ErrorMessage>
        ) : (
          Object.values(errors).map((errorMessage) => (
            <ErrorMessage key={errorMessage}>{errorMessage}</ErrorMessage>
          ))
        )}
        <S.BackButton onClick={handleBack}>
          {t('integrationProcess.auth.backButton', 'Back to previous page')}
        </S.BackButton>
      </>
    ),
  );

  useEffect(() => {
    if (isSuccess) {
      handleBack();
    }
  }, [isSuccess, handleBack]);

  const Logo = INTEGRATION_LOGO[type];

  return (
    <S.Container>
      <S.Content>
        <Logo height="35" />
        {renderContent(T.isEmpty(errors) && !queryParams.error)}
      </S.Content>
    </S.Container>
  );
};

import Button from '@bugbug/core/components/Button';
import LoaderBase from '@bugbug/core/components/Loader';
import mixins from '@bugbug/core/theme/mixins';
import { RegularParagraph } from '@bugbug/core/theme/typography';
import styled from 'styled-components';

import Card from '~/components/Card';
import PublicPage from '~/components/PublicPage';

export const Container = styled(PublicPage)``;

export const Content = styled(Card)`
  ${mixins.layout.flexColumnCenter}
  max-width: 348px;
  padding: 50px 32px;
  text-align: center;
  line-height: 17px;

  svg {
    margin-bottom: 16px;
    max-width: 200px;
  }
`;

export const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12px;
`;

export const Loader = styled(LoaderBase)`
  margin-right: 13px;
`;

export const Text = styled(RegularParagraph)`
  width: 100%;
  text-align: center;
  margin-top: 12px;
`;

export const BackButton = styled(Button)`
  margin-top: 12px;
  justify-content: center;
  width: 100%;
  flex: none;

  div {
    flex: initial;
  }
`;

import { COLOR } from '@bugbug/core/theme/colors';
import styled from 'styled-components';

import { Container as NavbarContainer } from '~/components/NavBar/NavBar.styled';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${NavbarContainer} ~ & {
    padding-bottom: 48px;
  }

  svg {
    max-width: 96px;
    width: 100%;
    height: 64px;
    margin-bottom: 18px;
    color: ${COLOR.GRAY_3};
  }
`;

export const Message = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  p {
    max-width: 290px;
    font-size: 13px;
    line-height: 16px;
    margin: 0;
    color: ${COLOR.DARK_GRAY};
    line-height: 20px;
    letter-spacing: -0.01em;
  }

  button {
    margin-top: 27px;
  }
`;

import { PAYMENT_REQUIRED } from 'http-status';
import { useCallback } from 'react';

import { MODAL_TYPE } from '~/components/modals';
import useActionState from '~/hooks/useActionState';
import useModal from '~/hooks/useModal';
import { SuiteActions } from '~/modules/suite/suite.redux';
import { TestActions } from '~/modules/test/test.redux';

const SubscriptionErrorModals = () => {
  const modal = useModal();

  const handle = useCallback((errors, status) => {
    if (status === PAYMENT_REQUIRED) {
      modal.show(MODAL_TYPE.UPGRADE_PLAN);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cloudRunRequestConfig = {
    reset: false,
    onFailure: handle,
  };

  useActionState(TestActions.startRunningRequest, cloudRunRequestConfig);
  useActionState(TestActions.startRunningSelectedTestsRequest, cloudRunRequestConfig);
  useActionState(SuiteActions.runRequest, cloudRunRequestConfig);
  useActionState(SuiteActions.runSelectedRequest, cloudRunRequestConfig);

  return null;
};

export default SubscriptionErrorModals;

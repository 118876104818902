import { Link } from 'react-router-dom';
import styled from 'styled-components';

import LogoSVG from '~/images/logo.svg?react';

export const Container = styled.div`
  width: 100%;
  min-height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  overflow: auto;

  > div {
    width: 100%;
    margin: auto;
  }

  ${({ theme }) => theme.media.desktop`
    padding-bottom: 70px;
  `}
`;

const LogoLinkBaseWrapper = styled.a`
  width: 160px;
  height: 35px;
  display: flex;
  align-self: flex-start;
  margin-top: -2px;
  margin-bottom: 30px;

  ${({ theme }) => theme.media.desktop`
    margin-bottom: 0;
  `}
`;

export const LogoHomePageWrapper = LogoLinkBaseWrapper;

export const LogoLinkWrapper = styled(Link)`
  ${LogoLinkBaseWrapper}
`;

export const Logo = styled(LogoSVG)`
  width: 110px;
`;

import * as msTeams from '@microsoft/teams-js';

import type { ConnectTeamsAPIIntegration } from '@bugbug/core/types/integrations';
import type { ProjectWithOrganization } from '@bugbug/core/types/projects';
import i18n from '~/translations';

export const getProjectItemName = (project: ProjectWithOrganization) =>
  i18n.t('integrationProcess.teams.config.project.option', '{{organizationName}} > {{name}}', {
    interpolation: { escapeValue: false },
    organizationName: project.organizationName,
    name: project.name,
  });

export const getTeamsAppData = async (): Promise<
  Omit<ConnectTeamsAPIIntegration, 'type' | 'projectId'>
> => {
  const config = await msTeams.pages.getConfig();
  const context = await msTeams.app.getContext();

  return {
    // @ts-expect-error - TS doesn't know about this field
    webhookUrl: config.webhookUrl,
    // @ts-expect-error - In this case we know that the field is present
    channelId: context.channel.id,
    // @ts-expect-error - In this case we know that the field is present
    name: context.channel.displayName,
    // @ts-expect-error - In this case we know that the field is present
    teamName: context.team.displayName,
  };
};

import React, { useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Card from '~/components/Card';
import urls from '~/views/urls';

import { Container, Box, Title, Icon, Text, BackButton } from './PasswordResetSuccess.styled';

const PasswordResetSuccess = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleBack = useCallback(() => {
    history.push(urls.login);
  }, [history]);

  return (
    <Container>
      <Helmet>
        <title>{t('passwordResetSuccess.pageTitle', 'Password reset!')}</title>
        <meta name="robots" content="none" />
      </Helmet>
      <Box>
        <Card>
          <Title>{t('passwordResetSuccess.title', 'Check your e-mail')}</Title>
          <Icon />
          <Text>
            {t(
              'passwordResetSuccess.description',
              "We've sent you an e-mail with link to reset your password.",
            )}
          </Text>
          <BackButton onClick={handleBack}>
            {t('passwordResetSuccess.backButton', 'Back to login')}
          </BackButton>
        </Card>
      </Box>
    </Container>
  );
};

export default PasswordResetSuccess;

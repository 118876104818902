import { ActionButton, BUTTON_VARIANT } from '@bugbug/core/components/Button';
import CheckboxBase from '@bugbug/core/components/Checkbox';
import IconComponent from '@bugbug/core/components/Icon';
import { FONT_WEIGHT, COLOR } from '@bugbug/core/theme';
import { RegularParagraph, H1 } from '@bugbug/core/theme/typography';
import styled from 'styled-components';

import PublicPage from '~/components/PublicPage';

export const Container = styled(PublicPage)``;

export const Box = styled.div`
  max-width: 348px;
`;

export const Title = styled(H1)`
  width: 100%;
  font-size: 17px;
  font-weight: ${FONT_WEIGHT.BOLD};
  text-align: center;
  color: ${COLOR.DARK_GRAY};
  line-height: 20px;
  padding-top: 6px;
`;

export const SubTitle = styled.p`
  width: 100%;
  text-align: center;
  color: ${COLOR.GRAY_12};
  padding-top: 7px;
  padding-bottom: 24px;
  margin: 0;
`;

export const Text = styled(RegularParagraph)`
  font-weight: ${FONT_WEIGHT.NORMAL};
  width: 100%;
  text-align: center;
  margin: 12px auto;
`;

export const Icon = styled(IconComponent).attrs(() => ({
  name: 'checkCircle',
}))`
  transform: scale(2.4);
  color: ${COLOR.SUCCESS};
  margin: 32px auto 10px;
`;

export const Button = styled(ActionButton).attrs(() => ({
  variant: BUTTON_VARIANT.PRIMARY,
}))`
  margin-top: 12px;
  justify-content: center;

  div {
    flex: initial;
  }
`;

export const Checkbox = styled(CheckboxBase)`
  margin-top: 12px;
  margin-bottom: 20px;
  width: 100%;
`;

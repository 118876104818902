import * as T from '@bugbug/core/utils/toolbox';
import qs from 'query-string';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

import type { RouteProps } from 'react-router';

import { selectIsUserLoggedIn, selectUserData } from '~/modules/user/user.selectors';
import urls from '~/views/urls';

const PrivateRoute = (props: RouteProps) => {
  const { location = { pathname: '', search: '' } } = props;
  const isLoggedIn = useSelector(selectIsUserLoggedIn);
  const userData = useSelector(selectUserData);

  if (!isLoggedIn && T.isEmpty(userData)) {
    const params = {
      return_url: encodeURIComponent(`${location.pathname}${location.search}`),
    };

    return <Redirect to={`${urls.login}?${qs.stringify(params)}`} />;
  }

  if (!isLoggedIn) {
    return <Redirect to={urls.login} />;
  }

  return <Route {...props} />;
};

export default PrivateRoute;

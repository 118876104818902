import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import config from '~/modules/config';
import urls, { shouldHideNavigation } from '~/views/urls';

import { Container, LogoHomePageWrapper, LogoLinkWrapper, Logo } from './PublicPage.styled';

const PublicPage = ({ className, children }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const isInvitationPage = location.pathname === urls.signUpByInvitation;
  const hiddenNavigation = shouldHideNavigation(location.pathname);

  const renderNoGoLogo = () => (
    <LogoHomePageWrapper title={t('default.homepageLogoTitle', 'BugBug')}>
      <Logo />
    </LogoHomePageWrapper>
  );

  const renderGoToHomePage = () => (
    <LogoHomePageWrapper href={config.HOME_PAGE} title={t('default.homepageLinkTitle')}>
      <Logo />
    </LogoHomePageWrapper>
  );

  const renderGoToAppHomePage = () => (
    <LogoLinkWrapper to={urls.home} title={t('default.homepageLinkTitle')}>
      <Logo />
    </LogoLinkWrapper>
  );

  const renderPage = () => {
    switch (true) {
      case isInvitationPage:
        return renderNoGoLogo();

      case hiddenNavigation:
        return renderGoToHomePage();

      default:
        return renderGoToAppHomePage();
    }
  };

  return (
    <Container className={className}>
      {renderPage()}
      {children}
    </Container>
  );
};

PublicPage.defaultProps = {
  className: null,
};

PublicPage.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default PublicPage;

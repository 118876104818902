import { Suspense, useLayoutEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';

import PrivateRoute from '~/components/PrivateRoute';
import { loadLazy } from '~/utils/rendering';
import AppLoader from '~/views/AppLoader';
import AuthProviderRedirectPage from '~/views/AuthProviderRedirectPage';
import { IntegrationProcess } from '~/views/IntegrationProcess/IntegrationProcess';
import Login from '~/views/Login';
import NotFound from '~/views/NotFound';
import Onboarding from '~/views/Onboarding';
import PasswordReset from '~/views/PasswordReset';
import PasswordResetConfirmation from '~/views/PasswordResetConfirmation';
import PasswordResetSuccess from '~/views/PasswordResetSuccess';
import { SignUp, SignUpByInvitation } from '~/views/SignUp';
import SignUpConfirmation from '~/views/SignUpConfirmation';
import SignUpSuccess from '~/views/SignUpSuccess';
import urls from '~/views/urls';

const Organizations = loadLazy(() => import('~/views/Organizations'));

interface RoutesProps {
  isLoggedIn: boolean;
}
const Routes = ({ isLoggedIn }: RoutesProps) => {
  const location = useLocation();
  const history = useHistory();

  useLayoutEffect(() => {
    if (!location.pathname.endsWith('/')) {
      history.replace(`${location.pathname}/${location.search}`);
    }
  }, [location, history]);

  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no" />
      </Helmet>
      <Suspense fallback={<AppLoader />}>
        <Switch>
          <Route path={urls.login} component={Login} exact />
          <Route path={urls.signUpConfirm} component={SignUpConfirmation} exact />
          <Route path={urls.signUp} component={SignUp} exact />
          <Route path={urls.signUpByInvitation} component={SignUpByInvitation} exact />
          <Route path={urls.signUpSuccess} component={SignUpSuccess} exact />
          <Route path={urls.passwordReset} component={PasswordReset} exact />
          <Route path={urls.passwordResetSuccess} component={PasswordResetSuccess} exact />
          <Route
            path={urls.passwordResetConfirmation}
            component={PasswordResetConfirmation}
            exact
          />
          <Route path={urls.authProviderRedirect} component={AuthProviderRedirectPage} exact />
          <Route
            path={[urls.integrationAuthRedirect, urls.integrationConfig]}
            component={IntegrationProcess}
          />
          <PrivateRoute path={urls.onboarding} component={Onboarding} />
          <PrivateRoute exact path={urls.organizations} component={Organizations} />
          <PrivateRoute exact path={urls.newOrganization} component={Organizations} />
          <PrivateRoute path={urls.organization} component={Organizations} />
          <PrivateRoute path={urls.account} component={Organizations} />
          <Route
            exact
            path={urls.home}
            render={() => <Redirect to={!isLoggedIn ? urls.login : urls.organizations} />}
          />
          <Route component={NotFound} />
        </Switch>
      </Suspense>
    </>
  );
};

export default Routes;

import DropdownBase, {
  ExpandIcon,
  ToggleButton,
  ToggleButtonLabel,
} from '@bugbug/core/components/Dropdown';
import Icon from '@bugbug/core/components/Icon';
import LinkBase from '@bugbug/core/components/Link';
import { COLOR } from '@bugbug/core/theme';
import styled, { css } from 'styled-components';

import LogoSVG from '~/images/logo.svg?react';

export const Container = styled.nav`
  width: 100%;
  min-height: 48px;
  height: 48px;
  flex: none;
  background-color: ${({ theme }) => theme.colors.background.surface};
  box-shadow: 0 0 2px 0 rgba(30, 32, 59, 0.2);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 13px;
  z-index: 7;

  ${ToggleButton} {
    border: 0;
    height: 100%;
    border-radius: 0;

    &:hover,
    &[aria-expanded='true'] {
      background-color: ${({ theme }) => theme.colors.background.primary99};
    }
  }
`;

export const Logo = styled(LogoSVG)`
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 14px;
`;

export const LogoAndSwitchContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

export const Content = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 0 0 0 10px;
  list-style: none;
  margin: 0;
  height: 100%;
`;

export const Item = styled.li<{ full?: boolean }>`
  padding: 0 16px;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  ${({ full }) =>
    full &&
    css`
      padding: 0;
    `}
`;

export const PlanInfo = styled.span`
  color: ${({ theme }) => theme.colors.text.lighter};
  font-size: 13px;
  line-height: 18px;
  padding: 0 3px 2px;
  display: inline-flex;
  align-items: center;
`;

export const PlanUpgradeLink = styled(LinkBase)`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: 14px;
  line-height: 18px;
  margin-left: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 6px;

  svg {
    margin-top: 2px;
  }
`;

export const UserActionsDropdownIcon = styled(Icon).attrs(() => ({
  name: 'userSimple',
}))`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  stroke: ${({ theme }) => theme.colors.brand};
`;

export const HelpDropdownIcon = styled(Icon).attrs(() => ({
  name: 'help',
}))`
  color: ${({ theme }) => theme.colors.brand};
  width: 21px;
  height: 21px;
  padding-right: 3px;
`;

export const HelpDropdownItemIcon = styled(Icon).attrs(() => ({
  name: 'help',
}))`
  height: 20px;
`;

export const Dropdown = styled(DropdownBase)`
  height: 100%;

  ${ToggleButton} {
    padding: 0 12px;
  }

  ${ToggleButtonLabel} {
    margin-top: 1px;
  }

  ${ExpandIcon} {
    margin-left: -1px;
    font-size: 20px;
    margin-right: -3px;
    color: ${({ theme }) => theme.colors.brand};
  }
`;

export const TutorialInfo = styled.div`
  color: ${({ theme }) => theme.colors.common.white};
  background-color: ${COLOR.DARK_GRAY_6};
  padding: 4px 14px 8px;
  line-height: 18px;
  border-radius: 4px;
  position: absolute;
  right: 0;
  width: 336px;
  top: 100%;
  transform: translate(0, 10px);
  pointer-events: none;

  &:after {
    content: '';
    position: absolute;
    right: 33px;
    top: -7px;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid ${COLOR.DARK_GRAY_6};
    clear: both;
  }
`;

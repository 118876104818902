import Link from '@bugbug/core/components/Link';
import React, { useLayoutEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import AuthProviders from '~/components/AuthProviders';
import Card from '~/components/Card';
import { selectIsUserLoggedIn } from '~/modules/user/user.selectors';
import urls, { reverse } from '~/views/urls';

import { Container, Box, Title, Text } from './Login.styled';
import LoginForm from './LoginForm';

const Login = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const isLoggedIn = useSelector(selectIsUserLoggedIn);

  useLayoutEffect(() => {
    if (isLoggedIn) {
      history.push(urls.home);
    }
  }, [isLoggedIn, history]);

  if (isLoggedIn) {
    return null;
  }

  return (
    <Container>
      <Helmet
        title={t('login.pageTitle', 'BugBug login')}
        link={[{ rel: 'canonical', href: urls.login }]}
      />
      <Box>
        <Card>
          <Title data-testid="LoginTitle">{t('login.title', 'Sign in')}</Title>
          <LoginForm />
          <Text>
            <Link to={reverse(urls.passwordReset)}>
              {t('login.passwordResetLink', 'Forgot password?')}
            </Link>
          </Text>
          <Text data-testid="RegisterText">
            {t('login.registerAccountQuestion', "Don't have an account? ")}
            <Link to={urls.signUp}>{t('login.signUpLink', 'Sign up')}</Link>
          </Text>
        </Card>
        <AuthProviders />
      </Box>
    </Container>
  );
};

export default Login;

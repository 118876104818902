import { FONT_WEIGHT, COLOR } from '@bugbug/core/theme';
import { RegularParagraph, H1 } from '@bugbug/core/theme/typography';
import styled from 'styled-components';

import PublicPage from '~/components/PublicPage';

export const Container = styled(PublicPage)``;

export const Box = styled.div`
  max-width: 348px;
`;

export const Title = styled(H1)`
  width: 100%;
  font-size: 17px;
  font-weight: ${FONT_WEIGHT.BOLD};
  text-align: center;
  color: ${COLOR.DARK_GRAY};
  line-height: 20px;
  padding-top: 6px;
  padding-bottom: 18px;
`;

export const Text = styled(RegularParagraph)`
  width: 100%;
  text-align: center;
  margin-top: 12px;
`;

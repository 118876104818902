import React, { useImperativeHandle, forwardRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import type { SideEffect } from '@bugbug/core/types/utils';
import GithubLogoImage from '~/images/github-logo.png';
import GoogleLogoImage from '~/images/google-logo.png';
import { AUTH_PROVIDER } from '~/modules/user/user.constants';
import { UserActions } from '~/modules/user/user.redux';
import analytics, { TRACK_EVENT_TYPE } from '~/services/analytics';

import {
  Container,
  SocialLoginLogo,
  GoogleLogo,
  ButtonContainer,
  Button,
} from './AuthProviders.styled';

export interface AuthProvidersRef {
  loginUsingGoogle: SideEffect;
  loginUsingGithub: SideEffect;
}

const AuthProviders = forwardRef<AuthProvidersRef>((props, ref) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const loginUsingProvider = useCallback(
    (provider) => {
      analytics.trackEvent(TRACK_EVENT_TYPE.START_SIGN_UP, { provider });
      dispatch(UserActions.getSocialAuthUrlRequest({ provider }));
    },
    [dispatch],
  );

  const githubLogin = useCallback(
    () => loginUsingProvider(AUTH_PROVIDER.GITHUB),
    [loginUsingProvider],
  );

  const googleLogin = useCallback(
    () => loginUsingProvider(AUTH_PROVIDER.GOOGLE),
    [loginUsingProvider],
  );

  useImperativeHandle(
    ref,
    () => ({
      loginUsingGoogle: googleLogin,
      loginUsingGithub: githubLogin,
    }),
    [googleLogin, githubLogin],
  );

  return (
    <Container data-testid="AuthProviders">
      <ButtonContainer>
        <Button
          onClick={githubLogin}
          aria-label={t('authProviders.github.ariaLabel', 'Login with Github')}
        >
          <SocialLoginLogo
            src={GithubLogoImage}
            alt={t('authProviders.github.logoAlt', 'Github logo')}
          />
        </Button>
      </ButtonContainer>
      <ButtonContainer>
        <Button
          onClick={googleLogin}
          aria-label={t('authProviders.google.ariaLabel', 'Login with Google')}
        >
          <GoogleLogo
            src={GoogleLogoImage}
            alt={t('authProviders.google.logoAlt', 'Google logo')}
          />
        </Button>
      </ButtonContainer>
    </Container>
  );
});

AuthProviders.displayName = 'AuthProviders';

export default AuthProviders;

import Button from '@bugbug/core/components/Button';
import * as msTeams from '@microsoft/teams-js';
import { useTranslation } from 'react-i18next';

import type { TeamsIntegrationAuth } from '../Teams.types';

import type { User } from '@bugbug/core/types/users';
import FormField from '~/components/FormField';
import useAppRoutes from '~/hooks/useAppRoutes';
import { useAppDispatch, useAppSelector } from '~/modules/store';
import { UIStateActions } from '~/modules/uiState';
import { UserActions } from '~/modules/user/user.redux';
import { selectUserData } from '~/modules/user/user.selectors';

import * as S from './TeamsConfig.styled';

interface AuthStepProps {
  onValidityChange: (isValid: boolean) => void;
}

export const AuthStep = ({ onValidityChange }: AuthStepProps) => {
  const { t } = useTranslation();
  const user = useAppSelector(selectUserData) as User;
  const appRoute = useAppRoutes('integrationConfig');
  const isLogged = user.id;
  const dispatch = useAppDispatch();

  const handleConnectBugBug = async (event) => {
    // https://learn.microsoft.com/en-us/microsoftteams/platform/tabs/how-to/authentication/auth-tab-aad?tabs=teamsjs-v2#navigate-to-the-authorization-page-from-your-pop-up-page
    try {
      event.preventDefault();

      const authData = await msTeams.authentication.authenticate({
        url: appRoute.getAbsoluteRouteUrl('integrationAuthRedirect', { type: 'teams' }),
        width: 450,
        height: 600,
      });

      const { token, integrationTrigger }: TeamsIntegrationAuth = JSON.parse(authData);

      dispatch(UserActions.loginSuccess({ token }));
      dispatch(UIStateActions.setIntegrationTrigger(integrationTrigger));
    } catch (error) {
      onValidityChange(false);
      msTeams.app.notifyFailure(error as msTeams.app.IFailedRequest);
    }
  };

  const handleLogout = () => {
    onValidityChange(false);
    dispatch(UserActions.clearSessionSuccess());
  };

  return (
    <FormField
      counter="1"
      label={t('integrationProcess.teams.config.auth.label', 'Login to BugBug')}
      description={
        isLogged
          ? undefined
          : t(
              'integrationProcess.teams.config.auth.description',
              'To connect your BugBug account, please log in.',
            )
      }
    >
      {user.id ? (
        <S.Step>
          <S.SuccessMessage>
            <section>
              {t('integrationProcess.teams.config.auth.success', 'You are logged in as:')}
              <span>{user.email} </span>
            </section>
            <section>
              <Button variant="primary" onClick={handleLogout} bordered>
                Logout
              </Button>
            </section>
          </S.SuccessMessage>
        </S.Step>
      ) : (
        <S.Step>
          <Button onClick={handleConnectBugBug} variant="primary">
            {t('integrationProcess.teams.config.auth.login', 'Login')}
          </Button>
        </S.Step>
      )}
    </FormField>
  );
};

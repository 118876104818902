import Loader from '@bugbug/core/components/Loader';
import { renderWhenTrue } from '@bugbug/core/utils/rendering';
import React, { useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMount } from 'react-use';

import Card from '~/components/Card';
import useActionState from '~/hooks/useActionState';
import useQueryString from '~/hooks/useQueryString';
import { UserActions } from '~/modules/user/user.redux';
import urls from '~/views/urls';

import { Container, Box, Icon, Text, LoaderContainer } from './SignUpConfirmation.styled';

const SignUpConfirmation = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const queryParams = useQueryString();

  const handleSuccess = useCallback(() => {
    history.push(urls.welcome, { isEmailConfirmed: true });
  }, [history]);

  const { isFailure } = useActionState(UserActions.confirmEmailRequest, {
    reset: false,
    onSuccess: handleSuccess,
  });

  useMount(() => {
    dispatch(UserActions.confirmEmailRequest({ key: queryParams.key }));
  });

  const renderFailure = renderWhenTrue(() => (
    <>
      <Icon />
      <Text>
        {t('signUpConfirmation.failure.description', 'Activation link is expired or invalid')}
      </Text>
    </>
  ));

  const renderLoader = renderWhenTrue(() => (
    <LoaderContainer>
      <Loader />
    </LoaderContainer>
  ));

  return (
    <Container>
      <Helmet>
        <title>{t('signUpConfirmation.pageTitle', 'Email confirmation')}</title>
        <meta name="robots" content="none" />
      </Helmet>
      <Box>
        <Card>
          {renderFailure(isFailure)}
          {renderLoader(!isFailure)}
        </Card>
      </Box>
    </Container>
  );
};

export default SignUpConfirmation;

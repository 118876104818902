import Loader from '@bugbug/core/components/Loader';
import PropTypes from 'prop-types';

import { Container, Logo } from './AppLoader.styled';

const AppLoader = ({ className }) => (
  <Container className={className} data-testid="AppLoader">
    <Logo />
    <Loader size="large" />
  </Container>
);

AppLoader.defaultProps = {
  className: null,
};

AppLoader.propTypes = {
  className: PropTypes.string,
};

export default AppLoader;

import { useCallback, useRef } from 'react';
import { useEvent, useUnmount } from 'react-use';

import useModal from '~/hooks/useModal';
import { hasVersionChanged } from '~/utils/meta';

const UPDATE_MODAL_DELAY = 60 * 1000;

const RuntimeModals = () => {
  const modal = useModal();
  const updateModalDelay = useRef();

  const openUpdateModal = useCallback(() => {
    if (updateModalDelay.current) {
      clearInterval(updateModalDelay.current);
    }
    updateModalDelay.current = setTimeout(() => {
      modal.show('update_available');
    }, UPDATE_MODAL_DELAY);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal.show]);

  const handleVisibilityChange = useCallback(async () => {
    if (document.visibilityState === 'visible' && (await hasVersionChanged())) {
      openUpdateModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal.show]);

  const handleOnline = useCallback(async () => {
    if (await hasVersionChanged()) {
      openUpdateModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal.show]);

  useUnmount(() => {
    clearTimeout(updateModalDelay.current);
  });

  useEvent('online', handleOnline);
  useEvent('visibilitychange', handleVisibilityChange);

  return null;
};

export default RuntimeModals;

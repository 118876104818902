import mixins from '@bugbug/core/theme/mixins';
import styled from 'styled-components';

export const Container = styled.div`
  ${mixins.layout.flexRowStretch};
  flex-direction: column;
  max-width: 500px;
  flex: 1;
  margin-top: 24px;
`;

export const Step = styled.div`
  padding: 12px 0;
  max-width: 350px;
`;

export const SuccessMessage = styled.div`
  ${mixins.layout.gridTwoColumns};
  gap: 10px;

  section:first-child {
    ${mixins.layout.flexColumnCenter};
    align-items: flex-start;
    text-align: left;

    span {
      color: ${({ theme }) => theme.colors.primary};
      font-weight: 600;
    }
  }

  section:last-child {
    ${mixins.layout.flexColumnCenter};
    align-items: flex-end;
  }
`;

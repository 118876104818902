import * as msTeams from '@microsoft/teams-js';
import { useCallback, useEffect, useState } from 'react';

import type { SideEffect } from '@bugbug/core/types/utils';

interface UseTeamsResult {
  inTeams?: boolean;
  setValidityState: (isValid: boolean) => void;
  registerOnSaveHandler: SideEffect<msTeams.pages.saveEventType>;
  registerOnRemoveHandler: SideEffect<msTeams.pages.removeEventType>;
}

export function useTeams(): UseTeamsResult {
  const [inTeams, setInTeams] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    (async () => {
      try {
        await msTeams.app.initialize();
        await msTeams.app.getContext();
        setInTeams(true);
      } catch {
        setInTeams(false);
      }
    })();
  }, []);

  const setValidityState = useCallback(
    (isValid: boolean) => {
      if (inTeams) {
        msTeams.pages.config.setValidityState(isValid);
      } else {
        console.warn('setValidityState is not available outside of Teams');
      }
    },
    [inTeams],
  );

  const registerOnSaveHandler = useCallback<SideEffect<msTeams.pages.saveEventType>>(
    (handler) => {
      if (inTeams) {
        // https://learn.microsoft.com/en-us/javascript/api/@microsoft/teams-js/pages.config?view=msteams-client-js-latest#@microsoft-teams-js-pages-config-registeronsavehandler
        msTeams.pages.config.registerOnSaveHandler(handler);
      } else {
        console.warn('registerOnSaveHandler is not available outside of Teams');
      }
    },
    [inTeams],
  );

  const registerOnRemoveHandler = useCallback<SideEffect<msTeams.pages.removeEventType>>(
    (handler) => {
      if (inTeams) {
        msTeams.pages.config.registerOnRemoveHandler(handler);
      } else {
        console.warn('registerOnRemoveHandler is not available outside of Teams');
      }
    },
    [inTeams],
  );

  return {
    inTeams,
    setValidityState,
    registerOnSaveHandler,
    registerOnRemoveHandler,
  };
}

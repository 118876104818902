import { ErrorMessage } from '@bugbug/core/theme/typography';
import { renderWhenTrueOtherwise } from '@bugbug/core/utils/rendering';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { useMount } from 'react-use';

import useActionState from '~/hooks/useActionState';
import useQueryString from '~/hooks/useQueryString';
import { SOCIAL_AUTH_STATE } from '~/modules/user/user.constants';
import { UserActions } from '~/modules/user/user.redux';
import localStorage from '~/services/localStorage';
import urls from '~/views/urls';

import { AUTH_PROVIDER_IMAGE, AUTH_PROVIDER_LABEL } from './AuthProviderRedirecPage.constants';
import {
  Container,
  Content,
  ProviderLogo,
  LoaderContainer,
  Loader,
  BackButton,
} from './AuthProviderRedirectPage.styled';

const AuthProviderRedirectPage = ({ className }) => {
  const { t } = useTranslation();
  const { provider } = useParams();
  const queryParams = useQueryString();
  const dispatch = useDispatch();
  const history = useHistory();
  const { errors, isLoading } = useActionState(UserActions.getSocialAccessTokenRequest, {
    reset: false,
  });
  const isCorrectState = localStorage.getItem(SOCIAL_AUTH_STATE, String) === queryParams.state;

  const handleBack = useCallback(() => {
    history.push(urls.login);
  }, [history]);

  const renderContent = renderWhenTrueOtherwise(
    () => (
      <LoaderContainer>
        <Loader />
        {AUTH_PROVIDER_LABEL[provider]}
      </LoaderContainer>
    ),
    () => (
      <>
        {Object.keys(errors || {}).map((key) => (
          <ErrorMessage key={key}>{errors[key]}</ErrorMessage>
        ))}
        <BackButton onClick={handleBack}>
          {t('authProviderRedirectPage.backButton', 'Back to login')}
        </BackButton>
      </>
    ),
  );

  useMount(() => {
    if (isCorrectState) {
      dispatch(UserActions.getSocialAccessTokenRequest({ provider, ...queryParams }));
    }
  });

  return (
    <Container className={className} data-testid="AuthProviderRedirectPage">
      <Content>
        <ProviderLogo src={AUTH_PROVIDER_IMAGE[provider]} alt={AUTH_PROVIDER_LABEL[provider]} />
        {renderContent(isLoading && !errors)}
      </Content>
    </Container>
  );
};

AuthProviderRedirectPage.defaultProps = {
  className: null,
};

AuthProviderRedirectPage.propTypes = {
  className: PropTypes.string,
};

export default AuthProviderRedirectPage;

import Input from '@bugbug/core/components/Input';
import Link from '@bugbug/core/components/Link';
import { renderWhenTrue } from '@bugbug/core/utils/rendering';
import { useFormik } from 'formik';
import React, { useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import Card from '~/components/Card';
import useActionState from '~/hooks/useActionState';
import useQueryString from '~/hooks/useQueryString';
import { UserActions } from '~/modules/user/user.redux';
import * as validators from '~/utils/validators';
import urls from '~/views/urls';

import {
  Container,
  Box,
  Title,
  Icon,
  Text,
  Form,
  FormField,
  SubmitButton,
} from './PasswordResetConfirmation.styled';

const PasswordResetConfirmSchema = Yup.object().shape({
  newPassword1: validators.newPasswordValidator,
  newPassword2: validators.repeatNewPasswordValidator,
});

const PasswordResetConfirmation = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const { u: uid, key: token } = useQueryString();

  const handleLogin = useCallback(() => {
    history.push(urls.login);
  }, [history]);

  const { errors, handleBlur, handleChange, handleSubmit, touched, values, setErrors } = useFormik({
    initialValues: {
      newPassword1: '',
      newPassword2: '',
      uid,
      token,
    },
    validationSchema: PasswordResetConfirmSchema,
    onSubmit: () => dispatch(UserActions.passwordResetConfirmRequest(values)),
  });

  const handleFailure = useCallback(
    (formErrors) => {
      setErrors(formErrors);
    },
    [setErrors],
  );

  const {
    isSuccess,
    isFailure,
    isLoading,
    errors: requestErrors,
  } = useActionState(UserActions.passwordResetConfirmRequest, {
    reset: false,
    onFailure: handleFailure,
  });

  const renderSuccess = renderWhenTrue(() => (
    <>
      <Title>{t('passwordResetConfirmation.success.title', 'Password changed')}</Title>
      <Icon />
      <Text>
        {t('passwordResetConfirmation.success.description', 'Password changed succesfully')}
      </Text>
      <SubmitButton onClick={handleLogin}>
        {t('passwordResetConfirmation.success.loginButton', 'Sign in')}
      </SubmitButton>
    </>
  ));

  const renderFailure = renderWhenTrue(() => (
    <Text>
      {t(
        'passwordResetConfirmation.failure.description',
        'Something went wrong while resetting password',
      )}
    </Text>
  ));

  const renderPasswordForm = renderWhenTrue(() => (
    <>
      <Title>{t('passwordResetConfirmation.title', 'Enter new password')}</Title>
      <Form onSubmit={handleSubmit} noValidate>
        <FormField>
          <Input
            type="password"
            name="newPassword1"
            placeholder={t('passwordResetConfirmation.passwordPlaceholder', 'New password')}
            fullWidth
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.newPassword1}
            autoComplete="off"
            error={touched.newPassword1 && errors.newPassword1}
          />
        </FormField>
        <FormField>
          <Input
            type="password"
            name="newPassword2"
            placeholder={t(
              'passwordResetConfirmation.confirmPasswordPlaceholder',
              'Confirm new password',
            )}
            fullWidth
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.newPassword2}
            autoComplete="off"
            error={touched.newPassword2 && errors.newPassword2}
          />
        </FormField>
        <SubmitButton type="submit" pending={isLoading}>
          {t('passwordResetConfirmation.submitButton', 'Change password')}
        </SubmitButton>
      </Form>
      <Text>
        {t('passwordResetConfirmation.signInQuestion', 'Remember old password? ')}
        <Link to={urls.login}>{t('passwordResetConfirmation.backToLogin', 'Sign in')}</Link>
      </Text>
    </>
  ));

  const hasNonFormError = isFailure && !!(requestErrors.token || requestErrors.uid);

  return (
    <Container>
      <Helmet>
        <title>{t('passwordResetConfirmation.pageTitle', 'Email confirmation')}</title>
        <meta name="robots" content="none" />
      </Helmet>
      <Box>
        <Card>
          {renderSuccess(isSuccess)}
          {renderPasswordForm(!isSuccess && !hasNonFormError)}
          {renderFailure(hasNonFormError)}
        </Card>
      </Box>
    </Container>
  );
};

export default PasswordResetConfirmation;

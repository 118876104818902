import githubLogoUrl from '~/images/github-logo.png';
import googleLogoUrl from '~/images/google-logo.png';
import { AUTH_PROVIDER } from '~/modules/user/user.constants';
import i18n from '~/translations';

export const AUTH_PROVIDER_IMAGE = {
  [AUTH_PROVIDER.GOOGLE]: googleLogoUrl,
  [AUTH_PROVIDER.GITHUB]: githubLogoUrl,
};

export const AUTH_PROVIDER_LABEL = {
  [AUTH_PROVIDER.GOOGLE]: i18n.t('authProviderRedirectPage.google', 'Signing in with Google...'),
  [AUTH_PROVIDER.GITHUB]: i18n.t('authProviderRedirectPage.github', 'Signing in with Github...'),
};

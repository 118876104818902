import { Offline, CaptureConsole } from '@sentry/integrations';
import * as Sentry from '@sentry/react';

import config from '~/modules/config';

const init = () => {
  if (!config.SENTRY_DSN) {
    return;
  }

  Sentry.init({
    dsn: config.SENTRY_DSN,
    environment: config.ENV,
    release: `webapp@${config.VERSION}`,
    beforeBreadcrumb(breadcrumb, hint) {
      if (breadcrumb.category === 'ui.click' && hint) {
        const label =
          hint.event.target.textContent ||
          hint.event.target.ariaLabel ||
          hint.event.target.parentElement.ariaLabel;

        if (label) {
          const extraMessage = label.length > 15 ? `${label.trim.substring(0, 15)}...` : label;
          // eslint-disable-next-line no-param-reassign
          breadcrumb.message += `[${extraMessage.trim()}]`;
        }
      }
      if (breadcrumb.category === 'console' && breadcrumb.level !== 'debug') {
        return null;
      }
      return breadcrumb;
    },
    normalizeDepth: 8,
    integrations: [
      new Offline(),
      new CaptureConsole({
        levels: ['error'],
      }),
    ],
  });
};

export default { init };

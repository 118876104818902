import { BUTTON_VARIANT } from '@bugbug/core/components/Button';
import Input from '@bugbug/core/components/Input';
import Link from '@bugbug/core/components/Link';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import * as Yup from 'yup';

import useActionState from '~/hooks/useActionState';
import { UserActions } from '~/modules/user/user.redux';
import * as validators from '~/utils/validators';
import urls from '~/views/urls';

import { Form, SubmitButton, FormField, ErrorMessage } from './LoginForm.styled';

const SignInSchema = Yup.object().shape({
  email: validators.emailValidator,
  password: validators.passwordValidator,
});

const LoginForm = ({ className }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { errors, handleBlur, handleChange, handleSubmit, touched, values, setErrors } = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: SignInSchema,
    onSubmit: () => dispatch(UserActions.loginRequest(values)),
  });

  const handleResendSuccess = useCallback(() => {
    history.push(urls.signUpSuccess, { email: values.email, redirected: true });
  }, [values.email, history]);

  const actionState = useActionState(UserActions.loginRequest, { onFailure: setErrors });
  useActionState(UserActions.resendActivationEmailRequest, {
    onSuccess: handleResendSuccess,
  });

  const handleResendEmail = useCallback(() => {
    dispatch(UserActions.resendActivationEmailRequest(values.email));
  }, [values.email, dispatch]);

  return (
    <Form className={className} onSubmit={handleSubmit} noValidate data-testid="LoginForm">
      {errors.invalid && <ErrorMessage>{errors.invalid}</ErrorMessage>}
      {values.email && errors.emailIsNotVerified && (
        <ErrorMessage>
          <Trans i18nKey="loginForm.notActiveEmail">
            You need to confirm your e-mail address first.
            <br />
            Didn't get the verification e-mail? <Link onClick={handleResendEmail}>Send again</Link>
          </Trans>
        </ErrorMessage>
      )}
      <FormField>
        <Input
          type="email"
          name="email"
          placeholder={t('loginForm.emailPlaceholder', 'E-mail')}
          fullWidth
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.email}
          autoComplete="email"
          error={touched.email && errors.email}
        />
      </FormField>
      <FormField>
        <Input
          type="password"
          name="password"
          placeholder={t('loginForm.passwordPlaceholder', 'Password')}
          fullWidth
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.password}
          autoComplete="current-password"
          error={touched.password && errors.password}
        />
      </FormField>
      <SubmitButton type="submit" variant={BUTTON_VARIANT.PRIMARY} pending={actionState.isLoading}>
        {t('loginForm.submitButton', 'Sign in')}
      </SubmitButton>
    </Form>
  );
};

LoginForm.propTypes = {
  className: PropTypes.string,
};

LoginForm.defaultProps = {
  className: null,
};

export default LoginForm;

import IconComponent from '@bugbug/core/components/Icon';
import { FONT_WEIGHT, COLOR } from '@bugbug/core/theme';
import { RegularParagraph } from '@bugbug/core/theme/typography';
import styled from 'styled-components';

import PublicPage from '~/components/PublicPage';

export const Container = styled(PublicPage)``;

export const Box = styled.div`
  max-width: 348px;
`;

export const Text = styled(RegularParagraph)`
  font-weight: ${FONT_WEIGHT.NORMAL};
  width: 100%;
  text-align: center;
  margin: 12px auto;
`;

export const Icon = styled(IconComponent).attrs(() => ({
  name: 'errorTriangle',
}))`
  transform: scale(2.4);
  color: ${COLOR.ERROR};
  margin: 32px auto 10px;
`;

export const LoaderContainer = styled.div`
  margin: 29px auto 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

import { ActionButton, BUTTON_VARIANT } from '@bugbug/core/components/Button';
import { FONT_WEIGHT, COLOR } from '@bugbug/core/theme';
import { RegularParagraph, H1 } from '@bugbug/core/theme/typography';
import styled from 'styled-components';

import FormFieldComponent from '~/components/FormField';
import PublicPage from '~/components/PublicPage';

export const Container = styled(PublicPage)``;

export const Box = styled.div`
  max-width: 348px;
`;

export const Title = styled(H1)`
  width: 100%;
  font-size: 17px;
  font-weight: ${FONT_WEIGHT.BOLD};
  text-align: center;
  color: ${COLOR.DARK_GRAY};
  line-height: 20px;
  padding-top: 6px;
  padding-bottom: 18px;
`;

export const Text = styled(RegularParagraph)`
  width: 100%;
  text-align: center;
  margin-top: 12px;
`;

export const SubmitButton = styled(ActionButton).attrs(() => ({
  variant: BUTTON_VARIANT.PRIMARY,
}))`
  margin-top: 12px;
  justify-content: center;
  width: 100%;
  flex: none;

  div {
    flex: initial;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-flow: column nowrap;
`;

export const FormField = styled(FormFieldComponent)`
  padding-bottom: 0;
  margin: 4px 0;
`;

import Loader from '@bugbug/core/components/Loader';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useUnmount } from 'react-use';

import AuthProviders from '~/components/AuthProviders';
import useActionState from '~/hooks/useActionState';
import useQueryString from '~/hooks/useQueryString';
import { useAppSelector } from '~/modules/store';
import { UserActions } from '~/modules/user/user.redux';
import {
  selectRegistrationEmail,
  selectRegistrationOrganizationName,
} from '~/modules/user/user.selectors';
import urls from '~/views/urls';

import * as S from './SignUp.styled';
import SignUpForm from './SignUpForm';

const SignUpByInvitation = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const { key } = useQueryString();

  const email = useAppSelector(selectRegistrationEmail) as string;
  const organization = useAppSelector(selectRegistrationOrganizationName) as string;

  const getRegistrationDataState = useActionState(UserActions.getRegistrationDataRequest);
  const loginState = useActionState(UserActions.loginRequest);

  useEffect(() => {
    if (key) {
      dispatch(UserActions.getRegistrationDataRequest(key));
    }
  }, [dispatch, key]);

  useEffect(() => {
    if (getRegistrationDataState.isFailure) {
      history.push(urls.notFound);
    }
  }, [getRegistrationDataState, history]);

  useUnmount(() => {
    dispatch(UserActions.clearRegistrationData());
  });

  const isLoading =
    (getRegistrationDataState.isLoading && !getRegistrationDataState.isFailure) ||
    loginState.isLoading;

  return (
    <S.Container>
      <Helmet
        title={t('signUpByInvitation.pageTitle', 'Get started with BugBug')}
        link={[{ rel: 'canonical', href: urls.signUpByInvitation }]}
      />

      <S.ByInvitationPageLayout>
        <S.Box>
          <S.ByInvitationCard>
            <S.Title>
              {t('signUpByInvitation.title', 'Welcome to')}
              <br />
              {organization}
            </S.Title>

            <S.SubTitle>
              {t('signUpByInvitation.subTitle', 'Create your password for')}
              <br />
              <S.Email>{email}</S.Email>
            </S.SubTitle>

            <SignUpForm email={email} registrationKey={key} />

            {isLoading && (
              <S.LoaderContainer>
                <Loader size="large" />
              </S.LoaderContainer>
            )}
          </S.ByInvitationCard>
          {!isLoading && <AuthProviders />}
        </S.Box>
      </S.ByInvitationPageLayout>
    </S.Container>
  );
};

export default SignUpByInvitation;

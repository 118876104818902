import * as T from '@bugbug/core/utils/toolbox';
import * as msTeams from '@microsoft/teams-js';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import type { TeamsIntegrationAuth } from '../Teams.types';
import type { RouteProps } from 'react-router';

import { INTEGRATION_LOGO } from '~/constants/integrations';
import useAppRoutes from '~/hooks/useAppRoutes';
import { useAppDispatch, useAppSelector } from '~/modules/store';
import { UIStateActions, selectIntegrationTrigger } from '~/modules/uiState';
import { selectUserData, selectUserToken } from '~/modules/user/user.selectors';

import * as S from '../../components/IntegrationAuthRedirect/IntegrationAuthRedirect.styled';

const Logo = INTEGRATION_LOGO.teams;

export const TeamsAuth = (props: RouteProps) => {
  const appRoute = useAppRoutes('integrationAuthRedirect');
  const token = useAppSelector(selectUserToken) as string | undefined;
  const isLoggedIn = !!token;
  const userData = useAppSelector(selectUserData);
  const integrationTrigger = useAppSelector(selectIntegrationTrigger);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { location = { pathname: '', search: '' } } = props;

  useEffect(() => {
    msTeams.app.initialize().then(() => {
      if (!isLoggedIn && T.isEmpty(userData)) {
        const query = {
          return_url: encodeURIComponent(`${location.pathname}${location.search}`),
        };

        appRoute.push('login', undefined, query);
      } else {
        const data: TeamsIntegrationAuth = {
          token,
          integrationTrigger,
        };
        msTeams.authentication.notifySuccess(JSON.stringify(data));
        dispatch(UIStateActions.removeIntegrationTrigger());
      }
    });
  }, [
    appRoute,
    isLoggedIn,
    location.pathname,
    location.search,
    token,
    userData,
    integrationTrigger,
    dispatch,
  ]);

  return (
    <S.Container>
      <S.Content>
        <Logo height="35" />
        <S.LoaderContainer>
          <S.Loader />
          {t('integrationProcess.teams.auth.connecting', 'Connecting to Microsoft Teams...')}
        </S.LoaderContainer>
      </S.Content>
    </S.Container>
  );
};

import styled from 'styled-components';

import PrintableDocument from '~/components/PrintableDocument';

export const AppContainer = styled(PrintableDocument)`
  display: flex;
  height: 100%;
`;

export const AppContent = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
  flex-direction: column;
`;

import mixins from '@bugbug/core/theme/mixins';
import { RegularParagraph, H1 } from '@bugbug/core/theme/typography';
import styled from 'styled-components';

import Card from '~/components/Card';
import PublicPage from '~/components/PublicPage';

export const Container = styled(PublicPage)`
  ${({ theme }) => theme.media.desktop`
    padding: 0;

    > a {
      position: absolute;
      top: 25px;
      left: 25px;
    }
  `}
`;

export const PageLayout = styled.div`
  ${mixins.layout.gridSingleColumn};
  flex: 1;

  > div:nth-child(1) {
    ${mixins.layout.flexColumnCenter};
  }

  > div:nth-child(2) {
    display: none;
  }

  ${({ theme }) => theme.media.desktop`
    ${mixins.layout.gridTwoColumns};

    > div:nth-child(1) {
      padding: 50px;
    }

    > div:nth-child(2) {
      padding: 0;
      ${mixins.layout.flexRowStretch};
    }
  `}

  ${({ theme }) => theme.media.desktopWide`
    grid-template-columns: 1fr 700px;
  `}
`;

export const Title = styled(H1)`
  ${mixins.font.medium};
  color: ${({ theme }) => theme.colors.text.normal};
  width: 100%;
  text-align: center;
  padding-top: 8px;
  line-height: 20px;
`;

export const SubTitle = styled.p`
  color: ${({ theme }) => theme.colors.text.secondary};
  width: 100%;
  text-align: center;
  padding-top: 16px;
  padding-bottom: 24px;
  margin: 0;
`;

export const Email = styled.span`
  overflow-wrap: anywhere;
`;

export const Text = styled(RegularParagraph)`
  width: 100%;
  text-align: center;
  margin-top: 12px;
`;

export const LoaderContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.common.white};
  z-index: 1;
`;

export const Box = styled.div`
  max-width: 348px;
`;

export const ByInvitationPageLayout = styled.div`
  ${mixins.layout.gridSingleColumn};
  ${mixins.layout.flexColumnCenter};

  flex: 1;
`;

export const ByInvitationCard = styled(Card)`
  width: 348px;
`;

import Input from '@bugbug/core/components/Input';
import Link from '@bugbug/core/components/Link';
import { ErrorMessage } from '@bugbug/core/theme/typography';
import { useFormik } from 'formik';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import Card from '~/components/Card';
import useActionState from '~/hooks/useActionState';
import { UserActions } from '~/modules/user/user.redux';
import * as validators from '~/utils/validators';
import urls from '~/views/urls';

import { Container, Box, Title, Form, FormField, Text, SubmitButton } from './PasswordReset.styled';

const PasswordResetSchema = Yup.object().shape({
  email: validators.emailValidator,
});

const PasswordReset = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { errors, handleBlur, handleChange, handleSubmit, touched, values, setErrors } = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: PasswordResetSchema,
    onSubmit: () => dispatch(UserActions.passwordResetRequest(values)),
  });

  const passwordResetRequestState = useActionState(UserActions.passwordResetRequest, {
    onFailure: (formErrors) => {
      setErrors(formErrors);
    },
  });

  return (
    <Container>
      <Helmet title={t('passwordReset.pageTitle', 'Password reset')} />
      <Box>
        <Card>
          <Title>{t('passwordReset.title', 'Forgot password')}</Title>
          <Form onSubmit={handleSubmit} noValidate>
            {errors.cantResetPasswordSocialAuth && (
              <ErrorMessage>{errors.cantResetPasswordSocialAuth}</ErrorMessage>
            )}
            <FormField>
              <Input
                type="email"
                name="email"
                placeholder={t('passwordReset.emailPlaceholder', 'Your e-mail address')}
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                autoComplete="off"
                error={touched.email && errors.email}
              />
            </FormField>
            <SubmitButton type="submit" pending={passwordResetRequestState.isLoading}>
              {t('passwordReset.submitButton', 'Reset password')}
            </SubmitButton>
          </Form>
          <Text>
            <Link to={urls.login}>{t('passwordReset.backToLogin', 'Back to login')}</Link>
          </Text>
        </Card>
      </Box>
    </Container>
  );
};

export default PasswordReset;

import { renderWhenTrue } from '@bugbug/core/utils/rendering';
import { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useMount, useUnmount, useUpdateEffect } from 'react-use';

import Card from '~/components/Card';
import useActionState from '~/hooks/useActionState';
import { UserActions } from '~/modules/user/user.redux';
import {
  selectIsSocialLogin,
  selectIsInvited,
  selectIsAllowedEmailMarketing,
} from '~/modules/user/user.selectors';
import analytics, { TRACK_EVENT_TYPE } from '~/services/analytics';

import { Container, Box, Title, Icon, Text, Button, Checkbox } from './Welcome.styled';

const Welcome = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const defaultMarketingConsent = useSelector(selectIsAllowedEmailMarketing);
  const [marketingConsent, setMarketingConsent] = useState(defaultMarketingConsent);

  useUpdateEffect(() => {
    setMarketingConsent(defaultMarketingConsent);
  }, [defaultMarketingConsent]);

  const isSocialLogin = useSelector(selectIsSocialLogin);
  const wasInvited = useSelector(selectIsInvited);
  const { isEmailConfirmed } = location.state || {};

  const handleMarketingConsentChange = useCallback((event) => {
    setMarketingConsent(event.target.checked);
  }, []);

  const handleContinue = useCallback(() => {
    dispatch(UserActions.updateMarketingConsentRequest(marketingConsent));
  }, [dispatch, marketingConsent]);

  const { isLoading, reset } = useActionState(UserActions.updateSettingsRequest, {
    reset: false,
  });

  useMount(() => {
    if (isSocialLogin || isEmailConfirmed) {
      analytics.trackEvent(TRACK_EVENT_TYPE.CONFIRM_SIGN_UP);
    }
  });

  useUnmount(() => {
    reset();
  });

  const renderConsent = renderWhenTrue(() => (
    <Checkbox checked={marketingConsent} onChange={handleMarketingConsentChange}>
      {t(
        'welcome.marketingConsent',
        'I want ro receive information about bugbug.io special offers on my e-mail',
      )}
    </Checkbox>
  ));

  return (
    <Container>
      <Helmet>
        <title>{t('welcome.pageTitle', 'Welcome!')}</title>
      </Helmet>
      <Box>
        <Card>
          <Title>{t('welcome.success.title', 'Welcome to bugbug.io!')}</Title>
          <Icon />
          <Text>
            {!isSocialLogin &&
              isEmailConfirmed &&
              t('welcome.success.emailInfo', 'Your email is confirmed!')}
            {!isSocialLogin && <br />}
            {!wasInvited &&
              t('welcome.success.descriptionRegular', "You're almost ready to start testing.")}
            {wasInvited &&
              t(
                'welcome.success.descriptionInvited',
                'All is set up! Now you can start using BugBug.',
              )}
          </Text>
          {renderConsent(isSocialLogin)}
          <Button onClick={handleContinue} pending={isLoading}>
            {t('welcome.continueButton', 'Continue')}
          </Button>
        </Card>
      </Box>
    </Container>
  );
};

export default Welcome;

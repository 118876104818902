import { Route, Switch } from 'react-router-dom';

import useAppRoutes from '~/hooks/useAppRoutes';

import { SlackAuth } from './Slack';
import { TeamsAuth, TeamsConfig } from './Teams';

export const IntegrationProcess = () => {
  const appRoute = useAppRoutes('integrationProcess');

  return (
    <Switch>
      {/* Slack */}
      <Route
        path={appRoute.getRouteUrl('integrationAuthRedirect', { type: 'slack' })}
        component={SlackAuth}
      />

      {/* Teams */}
      <Route
        path={appRoute.getRouteUrl('integrationConfig', { type: 'teams' })}
        component={TeamsConfig}
      />
      <Route
        path={appRoute.getRouteUrl('integrationAuthRedirect', { type: 'teams' })}
        component={TeamsAuth}
      />
    </Switch>
  );
};

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(updateLocale);
dayjs.extend(relativeTime);

dayjs.extend((option, Dayjs, dayjsInstance) => {
  dayjsInstance.duration = (duration, format, isFixed) => {
    const today = dayjs().startOf('day');
    const baseFormat = today.format('YYYY-MM-DD');
    const durationDate = dayjs(`${baseFormat} ${duration}`);

    return durationDate.diff(today, format, isFixed);
  };
});

dayjs.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'a few seconds',
    m: 'a minute',
    mm: '%dm',
    h: 'an hour',
    hh: '%dh',
    d: 'a day',
    dd: '%dd',
    M: 'a month',
    MM: '%dmth',
    y: 'a year',
    yy: '%d years',
  },
});

import Link from '@bugbug/core/components/Link';
import { useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useMount } from 'react-use';

import type { AuthProvidersRef } from '~/components/AuthProviders/AuthProviders';

import AuthProviders from '~/components/AuthProviders';
import Card from '~/components/Card';
import useQueryString from '~/hooks/useQueryString';
import analytics, { TRACK_EVENT_TYPE } from '~/services/analytics';
import urls from '~/views/urls';

import Benefits from './Benefits';
import * as S from './SignUp.styled';
import SignUpForm from './SignUpForm';

const SignUpRedirect = () => {
  const { key } = useQueryString();
  const history = useHistory();

  if (key) {
    history.push(`/invitation/?key=${key}`);
  }

  return <SignUp />;
};

const SignUp = () => {
  const { t } = useTranslation();

  const {
    email,
    source_url: referrer,
    'continue-with-google': continueWithGoogle,
    'continue-with-github': continueWithGithub,
  } = useQueryString();

  const decodedUrlEmail = email ? decodeURI(email) : email;
  const decodedReferrer = referrer ? decodeURI(referrer) : referrer;
  const authProviders = useRef<AuthProvidersRef>(null);

  useMount(() => {
    if (decodedReferrer && document.referrer) {
      analytics.trackEvent(TRACK_EVENT_TYPE.CLICK_SIGN_UP_ON_LANDING);
    } else {
      analytics.trackEvent(TRACK_EVENT_TYPE.VIEW_SIGN_UP);
    }

    if (continueWithGoogle === 'true') {
      authProviders.current?.loginUsingGoogle();
    }
    if (continueWithGithub === 'true') {
      authProviders.current?.loginUsingGithub();
    }
  });

  return (
    <S.Container>
      <Helmet
        title={t('signUp.pageTitle', 'Get started with BugBug')}
        link={[{ rel: 'canonical', href: urls.signUp }]}
      />
      <S.PageLayout>
        <div>
          <S.Box>
            <Card>
              <S.Title>{t('signUp.title', 'Get started for free')}</S.Title>

              <S.SubTitle>
                {t('signUp.subTitle.part1', 'Automate faster. No credit card needed.')}
                <br />
                {t('signUp.subTitle.part2', 'Free forever.')}
              </S.SubTitle>

              <SignUpForm email={decodedUrlEmail} />

              <S.Text>
                {t('signUp.signInQuestion', 'Got an account? ')}
                <Link to={urls.login}>{t('signUp.signInLink', 'Sign in')}</Link>
              </S.Text>
            </Card>

            <AuthProviders ref={authProviders} />
          </S.Box>
        </div>

        <div>
          <Benefits />
        </div>
      </S.PageLayout>
    </S.Container>
  );
};

export default SignUpRedirect;

import Button, { BUTTON_VARIANT } from '@bugbug/core/components/Button';
import PropTypes from 'prop-types';

import { Container, Message } from './ErrorPage.styled';

const ErrorPage = ({ message, Image, buttonLabel, onButtonClick }) => (
  <Container>
    <Image />
    <Message>
      {message}
      {buttonLabel && (
        <Button variant={BUTTON_VARIANT.PRIMARY} bordered onClick={onButtonClick}>
          {buttonLabel}
        </Button>
      )}
    </Message>
  </Container>
);

ErrorPage.propTypes = {
  Image: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.object,
    PropTypes.func,
    PropTypes.string,
  ]),
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.object]),
  buttonLabel: PropTypes.string,
  onButtonClick: PropTypes.func,
};

ErrorPage.defaultProps = {
  Image: () => null,
  message: '',
  buttonLabel: '',
  onButtonClick: Function.prototype,
};

export default ErrorPage;

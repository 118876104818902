import '@bugbug/core/theme/initial.css';
import '@bugbug/core/utils/dayjsExtensions';
import 'bootstrap-reboot/reboot.css';
import '~/translations';

import { GlobalStyle } from '@bugbug/core/theme/global';
import { ConnectedRouter } from 'connected-react-router';
import { enableMapSet } from 'immer';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { store, persistor } from '~/modules/store';
import storeRegistry from '~/modules/storeRegistry';
import analytics from '~/services/analytics';
import history from '~/utils/history';
import sentry from '~/utils/sentry';
import App from '~/views/App';

sentry.init();
enableMapSet();
storeRegistry.set(store);
analytics.setup();

const render = () => {
  ReactDOM.render(
    <>
      <GlobalStyle />
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ConnectedRouter history={history}>
            <App />
          </ConnectedRouter>
        </PersistGate>
      </Provider>
    </>,
    document.getElementById('app'),
  );
};

render();

import { BUTTON_VARIANT } from '@bugbug/core/components/Button';
import Input from '@bugbug/core/components/Input';
import Link from '@bugbug/core/components/Link';
import { useFormik } from 'formik';
import { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import type { SideEffect } from '@bugbug/core/types/utils';
import useActionState from '~/hooks/useActionState';
import { UserActions } from '~/modules/user/user.redux';
import analytics, { TRACK_EVENT_TYPE } from '~/services/analytics';
import * as validators from '~/utils/validators';
import urls from '~/views//urls';

import {
  Form,
  SubmitButton,
  FormField,
  TermsAndConditions,
  Checkbox,
  ErrorMessage,
} from './SignUpForm.styled';

const SignUpSchema = Yup.object().shape({
  email: validators.emailValidator,
  password1: validators.passwordValidator,
  password2: validators.repeatPasswordValidator,
  isAllowedEmailMarketing: Yup.bool().optional(),
});

interface SignUpFormProps {
  className?: string;
  email?: string;
  registrationKey?: string;
}

interface SignUpFormErrors {
  email?: string;
  emailIsNotVerified?: boolean;
  password1?: string;
  password2?: string;
  invalid?: string;
}

const SignUpForm = ({ email = '', registrationKey }: SignUpFormProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isInvitation = !!registrationKey;

  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    values,
    isSubmitting,
    setErrors,
    setFieldValue,
    setSubmitting,
  } = useFormik({
    initialValues: {
      email,
      password1: '',
      password2: '',
      isAllowedEmailMarketing: false,
    },
    validationSchema: SignUpSchema,
    onSubmit: () => {
      analytics.trackEvent(TRACK_EVENT_TYPE.START_SIGN_UP, { provider: 'regular' });
      dispatch(UserActions.registerRequest({ ...values, key: registrationKey }));
    },
  });
  const customErrors: SignUpFormErrors = errors;

  useEffect(() => {
    setFieldValue('email', email);
  }, [email, setFieldValue]);

  const handleFailure = useCallback<SideEffect<SignUpFormErrors>>((formErrors) => {
    setErrors(formErrors);
    setSubmitting(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useActionState(UserActions.registerRequest, { onFailure: handleFailure });
  useActionState(UserActions.loginRequest, { onFailure: handleFailure });

  return (
    <Form aria-label="form" onSubmit={handleSubmit} noValidate data-testid="SignUpForm">
      {customErrors.invalid && <ErrorMessage>{customErrors.invalid}</ErrorMessage>}
      {!isInvitation && (
        <FormField>
          <Input
            type="email"
            name="email"
            readOnly={isInvitation}
            placeholder={t('signUpForm.emailPlaceholder', 'E-mail')}
            fullWidth
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            autoComplete="off"
            error={
              (touched.email && errors.email) ||
              (customErrors.emailIsNotVerified &&
                t(
                  'signUpForm.notRelatedEmail',
                  'Provided email address is not associated with the invitation.',
                ))
            }
          />
        </FormField>
      )}
      <FormField>
        <Input
          type="password"
          name="password1"
          placeholder={t('signUpForm.passwordPlaceholder', 'Password')}
          fullWidth
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.password1}
          autoComplete="off"
          error={touched.password1 && errors.password1}
        />
      </FormField>
      <FormField>
        <Input
          type="password"
          name="password2"
          placeholder={t('signUpForm.confirmPasswordPlaceholder', 'Confirm password')}
          fullWidth
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.password2}
          autoComplete="off"
          error={touched.password2 && errors.password2}
        />
      </FormField>

      <Checkbox
        name="isAllowedEmailMarketing"
        checked={values.isAllowedEmailMarketing}
        onChange={handleChange}
      >
        {t(
          'signUpForm.isAllowedEmailMarketing',
          'I want to receive information about BugBug product updates.',
        )}
      </Checkbox>

      <TermsAndConditions>
        {t('signUpForm.termsAndConditionDesc', 'By continuing you accept the ')}
        <Link to={urls.termsAndConditions}>
          {t('signUpForm.termsAndConditionLink', 'terms and conditions')}
        </Link>
      </TermsAndConditions>

      <SubmitButton type="submit" variant={BUTTON_VARIANT.PRIMARY} pending={isSubmitting}>
        {isInvitation
          ? t('signUpForm.byInvitationSubmitButton', 'Continue')
          : t('signUpForm.submitButton', 'Create account')}
      </SubmitButton>
    </Form>
  );
};

export default SignUpForm;

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { mixpanelPageConfiguration } from '~/components/Mixpanel/Mixpanel.constants';
import { getAppRouteName } from '~/components/Mixpanel/Mixpanel.helpers';
import mixpanel from '~/services/analytics/mixpanel';

export const Mixpanel = () => {
  const location = useLocation();

  useEffect(() => {
    if (!mixpanel.isEnabled) {
      return;
    }
    const currentRoute = getAppRouteName(location.pathname);

    if (!currentRoute) {
      return;
    }

    const pageConfig = mixpanelPageConfiguration[currentRoute];

    if (pageConfig) {
      let distinctId;
      if (pageConfig.anonymous) {
        distinctId = mixpanel.getDistinctId();
        mixpanel.clearSession();
      }

      mixpanel.trackEvent('page_viewed', {
        url: location.pathname,
        page_name: pageConfig.name,
      });

      if (pageConfig.anonymous) {
        mixpanel.initUserSession(distinctId);
      }
    }
  }, [location.pathname]);

  return null;
};

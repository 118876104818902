import * as msTeams from '@microsoft/teams-js';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import type { User } from '@sentry/browser';

import useActionState from '~/hooks/useActionState';
import { useAppSelector } from '~/modules/store';
import { UserActions } from '~/modules/user/user.redux';
import { selectUserData } from '~/modules/user/user.selectors';

import { useTeams } from '../useTeams';

import { AuthStep } from './AuthStep';
import { ConfigurationStep } from './ConfigurationStep';
import { FinishStep } from './FinishStep';
import * as S from './TeamsConfig.styled';

export const TeamsConfig = () => {
  const { t } = useTranslation();
  const { inTeams, setValidityState, registerOnRemoveHandler, registerOnSaveHandler } = useTeams();
  const user = useAppSelector(selectUserData) as User;
  const getSingleUserState = useActionState(UserActions.getSingleRequest, {
    reset: false,
  });

  useEffect(() => {
    if (inTeams) {
      Sentry.setTag('subProject', 'ms-teams-iframe');
      msTeams.app.notifySuccess();
    }
  }, [inTeams]);

  if (inTeams === undefined) {
    // Teams context is not yet available
    return null;
  }

  if (!inTeams) {
    return (
      <div>
        {t(
          'integrationProcess.teams.config.notSupported',
          'This page is only available in Microsoft Teams.',
        )}
      </div>
    );
  }

  if (getSingleUserState.isLoading) {
    return null;
  }

  return (
    <S.Container>
      <AuthStep onValidityChange={setValidityState} />
      {user.id && (
        <>
          <ConfigurationStep
            onValidityChange={setValidityState}
            onSaveHandlerRegister={registerOnSaveHandler}
            onRemoveHandlerRegister={registerOnRemoveHandler}
          />
          <FinishStep />
        </>
      )}
    </S.Container>
  );
};

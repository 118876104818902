import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { useFirstMountState, useMount } from 'react-use';

import PrivateRoute from '~/components/PrivateRoute';
import { selectCurrentOrganization } from '~/modules/organization/organization.selectors';
import { UserActions } from '~/modules/user/user.redux';
import AppLoader from '~/views/AppLoader';
import NotFound from '~/views/NotFound';
import OrganizationSetup from '~/views/OrganizationSetup';
import ProfileSetup from '~/views/ProfileSetup';
import urls from '~/views/urls';
import Welcome from '~/views/Welcome';

const Redirect = () => {
  const dispatch = useDispatch();

  useMount(() => {
    dispatch(UserActions.redirectOnboardingRequest());
  });

  return <AppLoader />;
};

const Onboarding = () => {
  const currentOrganization = useSelector(selectCurrentOrganization);
  const isFirstMount = useFirstMountState();

  if (!currentOrganization) {
    return isFirstMount ? <AppLoader /> : null;
  }

  return (
    <Switch>
      <PrivateRoute path={urls.welcome} component={Welcome} exact />
      <PrivateRoute path={urls.setupProfile} component={ProfileSetup} exact />
      <PrivateRoute path={urls.setupOrganization} component={OrganizationSetup} exact />
      <PrivateRoute path={urls.onboardingRedirect} component={Redirect} exact />
      <Route path={urls.notFound} component={NotFound} />
    </Switch>
  );
};

Onboarding.propTypes = {};

export default Onboarding;

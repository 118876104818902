import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const Svg404 = (props, ref) => /* @__PURE__ */ jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: 264.17, height: 113.22, viewBox: "0 0 264.17 113.22", role: "img", ref, ...props, children: [
  /* @__PURE__ */ jsx("title", { children: "404-pixel" }),
  /* @__PURE__ */ jsx("g", { id: "ad9d9898-f182-4c56-aa38-5ac6fab3bf19", "data-name": "Layer 2", children: /* @__PURE__ */ jsx("g", { id: "b334336e-5a9f-48fa-a124-a459bf7e9897", "data-name": "Layer 1", children: /* @__PURE__ */ jsxs("g", { children: [
    /* @__PURE__ */ jsx("path", { d: "M66,113.22H56.61V94.35H0V56.61H18.87V37.74H37.74V18.87H56.61V0H75.48V75.48h9.43V94.35H75.48v18.87ZM47.17,75.48h9.44V37.74H37.74V56.61H18.87V75.48h28.3Z", style: {
      fill: "currentColor"
    } }),
    /* @__PURE__ */ jsx("path", { d: "M151,94.35V47.17h-9.44V66H122.65V84.91h-9.43v9.44H94.35V18.87h18.87V66h9.43V47.17h18.87V28.3H151V18.87h18.87V94.35H151Zm0-75.48H113.22V0H151V18.87Zm-9.44,94.35h-28.3V94.35H151v18.87Z", style: {
      fill: "currentColor"
    } }),
    /* @__PURE__ */ jsx("path", { d: "M245.3,113.22h-9.43V94.35H179.26V56.61h18.87V37.74H217V18.87h18.87V0h18.87V75.48h9.43V94.35h-9.43v18.87ZM226.43,75.48h9.44V37.74H217V56.61H198.13V75.48h28.3Z", style: {
      fill: "currentColor"
    } })
  ] }) }) })
] });
const ForwardRef = forwardRef(Svg404);
export default ForwardRef;

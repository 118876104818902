import { ActionButton } from '@bugbug/core/components/Button';
import CheckboxBase from '@bugbug/core/components/Checkbox';
import { COLOR } from '@bugbug/core/theme/colors';
import { ErrorMessage as ErrorMessageBase } from '@bugbug/core/theme/typography';
import styled from 'styled-components';

import FormFieldComponent from '~/components/FormField';

export const Form = styled.form`
  display: flex;
  flex-flow: column nowrap;
`;

export const SubmitButton = styled(ActionButton)`
  margin-top: 12px;
  justify-content: center;

  div {
    flex: initial;
  }
`;

export const FormField = styled(FormFieldComponent)`
  padding-bottom: 0;
  margin: 4px 0;
`;

export const Checkbox = styled(CheckboxBase)`
  margin-top: 20px;
  margin-bottom: 3px;
  width: 100%;

  label {
    margin-top: -2px;
  }
`;

export const TermsAndConditions = styled.p`
  margin-bottom: 0;
  margin-top: 9px;
  padding-top: 9px;
  font-size: 11px;
  text-align: center;
  line-height: 20px;
  border-top: 1px solid ${COLOR.GRAY_9};

  ${({ theme }) => theme.media.tablet`
    font-size: 12px;
  `}
`;

export const ErrorMessage = styled(ErrorMessageBase)`
  a {
    text-decoration: underline;
    color: inherit;
  }
`;

import Button from '@bugbug/core/components/Button';
import Icon from '@bugbug/core/components/Icon';
import React from 'react';

import i18n from '~/translations';

import { Container, Message } from './ErrorBoundary.styled';

const handleRefresh = () => {
  window.location.reload();
};

const ErrorBoundary = () => (
  <Container aria-label={i18n.t('errorBoundary.label', 'Unhandled error')}>
    <Icon name="errorTriangle" />
    <Message>
      {i18n.t(
        'errorBoundary.message',
        'An unexpected, critical error has occured. Information has been sent to administrators.',
      )}
      <Button onClick={handleRefresh}>{i18n.t('errorBoundary.button', 'Refresh the page')}</Button>
    </Message>
  </Container>
);

ErrorBoundary.defaultProps = {};

ErrorBoundary.propTypes = {};

export default ErrorBoundary;

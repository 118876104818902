import Button, { BUTTON_VARIANT, ActionButton } from '@bugbug/core/components/Button';
import IconComponent from '@bugbug/core/components/Icon';
import { FONT_WEIGHT, COLOR } from '@bugbug/core/theme';
import { RegularParagraph, H1 } from '@bugbug/core/theme/typography';
import styled, { css } from 'styled-components';

import FormFieldBase, { Description } from '~/components/FormField';
import PublicPage from '~/components/PublicPage';

export const Container = styled(PublicPage)``;

export const Box = styled.div`
  max-width: 348px;
`;

export const Title = styled(H1)`
  width: 100%;
  font-size: 17px;
  font-weight: ${FONT_WEIGHT.BOLD};
  text-align: center;
  color: ${COLOR.DARK_GRAY};
  line-height: 20px;
  padding-top: 6px;
  padding-bottom: 32px;
`;

const buttonStyles = css`
  justify-content: center;

  div {
    flex: initial;
  }
`;

export const SubmitButton = styled(ActionButton).attrs(() => ({
  variant: BUTTON_VARIANT.PRIMARY,
}))`
  ${buttonStyles}
`;

export const CancelButton = styled(Button).attrs(() => ({
  variant: BUTTON_VARIANT.PRIMARY,
  bordered: true,
}))`
  ${buttonStyles}
  margin-top: 8px;
`;

export const Text = styled(RegularParagraph)`
  font-weight: ${FONT_WEIGHT.NORMAL};
  width: 100%;
  text-align: center;
  line-height: 18px;
  margin: 24px auto 20px;
`;

export const Icon = styled(IconComponent).attrs(() => ({
  name: 'organization',
}))`
  transform: scale(1.58);
  color: ${COLOR.PRIMARY};
  margin: 4px auto 0;
`;

export const FormField = styled(FormFieldBase)`
  padding-bottom: 0;
  margin: 2px 0 30px;

  ${Description} {
    width: 100%;
    padding: 4px 0;
    color: ${COLOR.GRAY_12};
    font-size: 12px;
    line-height: 16px;
    text-align: center;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-flow: column nowrap;
`;

export const InfoBoxIcon = styled(IconComponent).attrs(() => ({
  name: 'info',
}))`
  && {
    font-size: 22px;
  }
  margin-right: 6px;
  margin-top: 2px;
  opacity: 0.53;
`;

export const InfoBox = styled.div`
  display: flex;
  padding: 3px 0 6px 0;
  border-radius: 5px;
  margin-bottom: 30px;
  margin-top: -5px;

  ${Text} {
    text-align: left;
    margin: 0;
  }
`;

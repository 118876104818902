import Input from '@bugbug/core/components/Input';
import { renderWhenTrue } from '@bugbug/core/utils/rendering';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMount } from 'react-use';
import * as Yup from 'yup';

import Card from '~/components/Card';
import useActionState from '~/hooks/useActionState';
import { OrganizationActions } from '~/modules/organization/organization.redux';
import { UserActions } from '~/modules/user/user.redux';
import { selectOnboardingData } from '~/modules/user/user.selectors';
import analytics, { TRACK_EVENT_TYPE } from '~/services/analytics';
import * as validators from '~/utils/validators';
import urls from '~/views/urls';

import {
  Container,
  Form,
  Box,
  Title,
  FormField,
  SubmitButton,
  CancelButton,
  Icon,
  Text,
  InfoBox,
  InfoBoxIcon,
} from './OrganizationSetup.styled';

const OrganizationSetupSchema = Yup.object().shape({
  name: validators.nameValidator,
});

const OrganizationSetup = ({ creator }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const profileData = useSelector(selectOnboardingData);

  const actionCreator = creator
    ? OrganizationActions.createRequest
    : UserActions.updateOnboardingOrganizationRequest;

  useMount(() => {
    if (!creator) {
      analytics.trackEvent(TRACK_EVENT_TYPE.VIEW_ORGANIZATION_SIGN_UP);
    }
  });

  const handleOnSubmit = useCallback(
    (values) => {
      if (!creator) {
        analytics.trackEvent(TRACK_EVENT_TYPE.CREATE_ORGANIZATION_SIGN_UP);
      }
      dispatch(actionCreator(creator ? values : values.name));
    },
    [dispatch, actionCreator, creator],
  );

  const defaultName = profileData.firstName
    ? t('organizationSetup.nameByFirstName', "{{ firstName }}'s organization", profileData)
    : t('organizationSetup.defaultName', 'My organization');

  const initialName = profileData.organization.companyName || defaultName;

  const {
    errors,
    handleBlur,
    handleChange,
    isSubmitting,
    handleSubmit,
    values,
    setErrors,
    setSubmitting,
  } = useFormik({
    initialValues: {
      name: initialName,
    },
    validationSchema: OrganizationSetupSchema,
    onSubmit: handleOnSubmit,
  });

  const handleFailure = useCallback(
    (stateErrors) => {
      if (stateErrors) {
        setErrors(stateErrors);
      }
      setSubmitting(false);
    },
    [setSubmitting, setErrors],
  );

  const handleSuccess = useCallback(() => {
    setSubmitting(false);
    history.push(urls.home);
  }, [history, setSubmitting]);

  const handleCancel = useCallback(() => {
    setSubmitting(false);
    history.goBack();
  }, [history, setSubmitting]);

  const { isLoading } = useActionState(actionCreator, {
    onSuccess: handleSuccess,
    onFailure: handleFailure,
  });

  const renderInfoBox = renderWhenTrue(() => (
    <InfoBox>
      <InfoBoxIcon />
      <Text>
        {t(
          'organizationSetup.extraInfo',
          'Please note that each organization uses a separate subscription plan.',
        )}
      </Text>
    </InfoBox>
  ));

  const renderCancelButton = renderWhenTrue(() => (
    <CancelButton onClick={handleCancel}>{t('default.button.cancel')}</CancelButton>
  ));

  return (
    <Container>
      <Box>
        <Card>
          <Form onSubmit={handleSubmit}>
            <Title>
              {creator
                ? t('organizationSetup.titleNew', 'New organization')
                : t('organizationSetup.titleContinue', 'Set up your organization')}
            </Title>
            <Icon />
            <Text>
              {t(
                'organizationSetup.description',
                'When you invite people to your organization they will have access to all your projects',
              )}
            </Text>
            <FormField
              description={t(
                'organizationSetup.nameField.description',
                'You can change organization name later in settings',
              )}
            >
              <Input
                data-hj-allow
                autoFocus
                name="name"
                fullWidth
                placeholder={t(
                  'organizationSetup.nameField.placeholder',
                  'Enter organization name',
                )}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                error={errors.name}
              />
            </FormField>
            {renderInfoBox(creator)}
            <SubmitButton onClick={handleSubmit} disabled={isSubmitting} pending={isLoading}>
              {creator
                ? t('organizationSetup.create', 'Create organization')
                : t('organizationSetup.continue', 'Continue')}
            </SubmitButton>
            {renderCancelButton(creator)}
          </Form>
        </Card>
      </Box>
    </Container>
  );
};

OrganizationSetup.defaultProps = {
  creator: false,
};

OrganizationSetup.propTypes = {
  creator: PropTypes.bool,
};

export default OrganizationSetup;

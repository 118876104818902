import Icon from '@bugbug/core/components/Icon';
import { useTranslation } from 'react-i18next';

import { BENEFITS_LIST, BRAND24_LOGO, BRANDS_LIST } from './Benefits.constants';
import * as S from './Benefits.styled';

const Benefits = () => {
  const { t } = useTranslation();

  return (
    <S.Container data-testid="Benefits">
      <blockquote>
        <img src={BRAND24_LOGO.logoUrl} alt={BRAND24_LOGO.alt} />
        <p>
          {t(
            'signUp.benefits.quote.text',
            '"I was surprised at how reliable BugBug\'s tests recording is. I tried a few competitive solutions, but none of them were as seamless as BugBug."',
          )}
        </p>
        <footer>{t('signUp.benefits.quote.author', 'Krzysztof Studniarek, Product Owner')}</footer>
      </blockquote>
      <S.BenefitsList>
        {BENEFITS_LIST.map(({ label }) => (
          <li key={label}>
            <Icon name="check" /> {label}
          </li>
        ))}
      </S.BenefitsList>
      <S.BrandsList>
        {BRANDS_LIST.map(({ logoUrl, alt }) => (
          <img key={alt} src={logoUrl} alt={alt} />
        ))}
      </S.BrandsList>
      <p>
        {t(
          'signUp.benefits.brands.description',
          'Join hundreds of companies optimizing their software testing process',
        )}
      </p>
    </S.Container>
  );
};

export default Benefits;

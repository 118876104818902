import Input from '@bugbug/core/components/Input';
import Select, { SelectOption } from '@bugbug/core/components/Select';
import { useFormik } from 'formik';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMount, useUnmount } from 'react-use';
import * as Yup from 'yup';

import Card from '~/components/Card';
import useActionState from '~/hooks/useActionState';
import { ONBOARDING_STEP } from '~/modules/constans';
import { UserActions } from '~/modules/user/user.redux';
import { selectOnboardingData, selectUserName } from '~/modules/user/user.selectors';
import analytics, { TRACK_EVENT_TYPE } from '~/services/analytics';
import * as validators from '~/utils/validators';
import urls from '~/views/urls';

import {
  COMPANY_ROLES,
  COMPANY_ROLE_LABEL,
  COMPANY_SIZES,
  COMPANY_SIZE_LABELS,
} from './ProfileSetup.constants';
import {
  Container,
  Form,
  Box,
  Title,
  FormField,
  SubmitButton,
  SkipButton,
  Icon,
  Text,
} from './ProfileSetup.styled';

const ProfileSetupSchema = Yup.object().shape({
  firstName: validators.nameValidator.optional(),
  lastName: validators.nameValidator.optional(),
  organization: Yup.object().shape({
    companyName: validators.nameValidator.nullable().optional(),
    companySize: Yup.string()
      .oneOf([...COMPANY_SIZES, null])
      .nullable()
      .optional(),
  }),
  role: Yup.string()
    .oneOf([...COMPANY_ROLES, null])
    .nullable()
    .optional(),
});

const ProfileSetup = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const initialProfileData = useSelector(selectOnboardingData);
  const username = useSelector(selectUserName);

  const goToOrganizationSetup = useCallback(() => {
    history.push(urls.setupOrganization);
  }, [history]);

  const handleOnSubmit = useCallback(
    (values) => {
      dispatch(UserActions.updateOnboardingProfileRequest(values));
    },
    [dispatch],
  );

  const handleSkip = useCallback(() => {
    dispatch(
      UserActions.skipOnboardingStepRequest(ONBOARDING_STEP.ORGANIZATION, {
        reqId: ONBOARDING_STEP.PROFILE,
      }),
    );
  }, [dispatch]);

  const {
    errors,
    handleBlur,
    handleChange,
    isSubmitting,
    touched,
    handleSubmit,
    values,
    setErrors,
    setSubmitting,
  } = useFormik({
    initialValues: initialProfileData,
    validationSchema: ProfileSetupSchema,
    onSubmit: handleOnSubmit,
  });

  const handleFailure = useCallback(
    (stateErrors) => {
      if (stateErrors) {
        setErrors(stateErrors);
      }
      setSubmitting(false);
    },
    [setSubmitting, setErrors],
  );

  const handleSuccess = useCallback(() => {
    analytics.trackEvent(TRACK_EVENT_TYPE.CREATE_PROFILE_SIGN_UP);
    setSubmitting(false);
    goToOrganizationSetup();
  }, [goToOrganizationSetup, setSubmitting]);

  const { isLoading } = useActionState(UserActions.updateOnboardingProfileRequest, {
    onSuccess: handleSuccess,
    onFailure: handleFailure,
  });

  const { isLoading: isSkipLoading, reset } = useActionState(
    UserActions.skipOnboardingStepRequest,
    {
      onSuccess: handleSuccess,
      onFailure: handleFailure,
      reset: false,
      reqId: ONBOARDING_STEP.PROFILE,
    },
  );

  useMount(() => {
    analytics.trackEvent(TRACK_EVENT_TYPE.VIEW_PROFILE_SIGN_UP);
  });

  useUnmount(() => {
    reset();
  });

  const organizationErrors = errors.organization || {};
  const organizationTouched = touched.organization || {};

  return (
    <Container data-testid="ProfileSetup">
      <Box>
        <Card>
          <Form>
            <Title>{t('profileSetup.title', 'Finish up your profile')}</Title>
            <Icon />
            <Text>{username}</Text>
            <FormField label={t('profileSetup.firstName', 'First name')}>
              <Input
                name="firstName"
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.firstName}
                error={errors.firstName}
              />
            </FormField>
            <FormField label={t('profileSetup.lastName', 'Last name')}>
              <Input
                name="lastName"
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.lastName}
                error={errors.lastName}
              />
            </FormField>
            <FormField label={t('profileSetup.company', 'Company')}>
              <Input
                name="organization.companyName"
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.organization.companyName}
                error={organizationErrors.companyName}
              />
            </FormField>
            <FormField label={t('profileSetup.companySize.label', 'Company size')}>
              <Select
                name="organization.companySize"
                fullWidth
                placeholder={t('profileSetup.companySize.selectLabel', 'Select size')}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.organization.companySize}
                error={organizationTouched.companySize && organizationErrors.companySize}
              >
                {COMPANY_SIZES.map((sizeType) => (
                  <SelectOption key={sizeType} value={sizeType}>
                    {COMPANY_SIZE_LABELS[sizeType]}
                  </SelectOption>
                ))}
              </Select>
            </FormField>
            <FormField label={t('profileSetup.role.label', 'Your role')}>
              <Select
                name="role"
                fullWidth
                placeholder={t('profileSetup.role.selectLabel', 'Select role')}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.role}
                error={touched.role && errors.role}
              >
                {COMPANY_ROLES.map((roleType) => (
                  <SelectOption key={roleType} value={roleType}>
                    {COMPANY_ROLE_LABEL[roleType]}
                  </SelectOption>
                ))}
              </Select>
            </FormField>
            <SubmitButton onClick={handleSubmit} disabled={isSubmitting} pending={isLoading}>
              {t('profileSetup.submit', 'Continue')}
            </SubmitButton>
            <SkipButton type="button" onClick={handleSkip} pending={isSkipLoading}>
              {t('profileSetup.skip', 'Skip')}
            </SkipButton>
          </Form>
        </Card>
      </Box>
    </Container>
  );
};

ProfileSetup.defaultProps = {};
ProfileSetup.propTypes = {};

export default ProfileSetup;

import i18n from '~/translations';

export const COMPANY_ROLE = {
  QA_ENGINEER: 'qa',
  FRONTEND_DEVELOPER: 'frontend-developer',
  BACKEND_DEVELOPER: 'backend-developer',
  MOBILE_DEVELOPER: 'mobile-developer',
  DEV_OPS: 'devops',
  PRODUCT_OWNER: 'product-owner',
  PROJECT_MANAGER: 'project-manager',
  CTO_TECH_LEAD: 'cto-cio-tech-lead',
  CEO_EXECUTIVE: 'ceo-executive',
  CUSTOM_SUPPORT: 'customer-support',
  FINANCE_ACCOUNTS: 'finance-accounts',
  OTHER: 'other',
};

export const COMPANY_ROLES = [
  COMPANY_ROLE.QA_ENGINEER,
  COMPANY_ROLE.FRONTEND_DEVELOPER,
  COMPANY_ROLE.BACKEND_DEVELOPER,
  COMPANY_ROLE.MOBILE_DEVELOPER,
  COMPANY_ROLE.DEV_OPS,
  COMPANY_ROLE.PRODUCT_OWNER,
  COMPANY_ROLE.PROJECT_MANAGER,
  COMPANY_ROLE.CTO_TECH_LEAD,
  COMPANY_ROLE.CEO_EXECUTIVE,
  COMPANY_ROLE.CUSTOM_SUPPORT,
  COMPANY_ROLE.FINANCE_ACCOUNTS,
  COMPANY_ROLE.OTHER,
];

export const COMPANY_ROLE_LABEL = {
  [COMPANY_ROLE.QA_ENGINEER]: i18n.t('profileSetup.role.qa', 'QA Engineer'),
  [COMPANY_ROLE.FRONTEND_DEVELOPER]: i18n.t('profileSetup.role.frontendDev', 'Frontend Developer'),
  [COMPANY_ROLE.BACKEND_DEVELOPER]: i18n.t('profileSetup.role.backendDev', 'Backend Developer'),
  [COMPANY_ROLE.MOBILE_DEVELOPER]: i18n.t('profileSetup.role.mobileDev', 'Mobile Developer'),
  [COMPANY_ROLE.DEV_OPS]: i18n.t('profileSetup.role.devOps', 'DevOps / Operations'),
  [COMPANY_ROLE.PRODUCT_OWNER]: i18n.t('profileSetup.role.productOwner', 'Product Owner / Manager'),
  [COMPANY_ROLE.PROJECT_MANAGER]: i18n.t('profileSetup.role.projectManager', 'Project Manager'),
  [COMPANY_ROLE.CTO_TECH_LEAD]: i18n.t('profileSetup.role.cto', 'CTO / CIO / Tech Lead'),
  [COMPANY_ROLE.CEO_EXECUTIVE]: i18n.t('profileSetup.role.ceo', 'CEO / Executive'),
  [COMPANY_ROLE.CUSTOM_SUPPORT]: i18n.t('profileSetup.role.customerSupport', 'Customer Support'),
  [COMPANY_ROLE.FINANCE_ACCOUNTS]: i18n.t('profileSetup.role.finance', 'Finance / Accounts'),
  [COMPANY_ROLE.OTHER]: i18n.t('profileSetup.role.other', 'Other'),
};

export const COMPANY_SIZE = {
  TINY: '1-10',
  SMALL: '11-50',
  REGULAR: '51-250',
  BIG: '251-500',
  ENTERPRISE: '500+',
};

export const COMPANY_SIZES = [
  COMPANY_SIZE.TINY,
  COMPANY_SIZE.SMALL,
  COMPANY_SIZE.REGULAR,
  COMPANY_SIZE.BIG,
  COMPANY_SIZE.ENTERPRISE,
];

export const COMPANY_SIZE_LABELS = {
  [COMPANY_SIZE.TINY]: i18n.t('profileSetup.companySize.tiny', '1-10'),
  [COMPANY_SIZE.SMALL]: i18n.t('profileSetup.companySize.small', '11-50'),
  [COMPANY_SIZE.REGULAR]: i18n.t('profileSetup.companySize.regular', '51-250'),
  [COMPANY_SIZE.BIG]: i18n.t('profileSetup.companySize.big', '251-500'),
  [COMPANY_SIZE.ENTERPRISE]: i18n.t('profileSetup.companySize.enterprise', '500+'),
};

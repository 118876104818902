import { BUTTON_VARIANT, ActionButton } from '@bugbug/core/components/Button';
import IconComponent from '@bugbug/core/components/Icon';
import { FONT_WEIGHT, COLOR } from '@bugbug/core/theme';
import { H1, RegularParagraph } from '@bugbug/core/theme/typography';
import styled from 'styled-components';

import FormFieldBase from '~/components/FormField';
import PublicPage from '~/components/PublicPage';

export const Container = styled(PublicPage)``;

export const Box = styled.div`
  max-width: 348px;
`;

export const Title = styled(H1)`
  width: 100%;
  font-size: 17px;
  font-weight: ${FONT_WEIGHT.BOLD};
  text-align: center;
  color: ${COLOR.DARK_GRAY};
  line-height: 20px;
  padding-top: 6px;
  padding-bottom: 32px;
`;

export const Text = styled(RegularParagraph)`
  font-weight: ${FONT_WEIGHT.NORMAL};
  width: 100%;
  text-align: center;
  line-height: 18px;
  margin: 2px auto 20px;
  overflow-wrap: anywhere;

  @media only screen and (max-height: 800px) {
    display: none;
  }
`;

export const Icon = styled(IconComponent).attrs(() => ({
  name: 'userSimple',
}))`
  transform: scale(1.58);
  stroke: ${COLOR.PRIMARY};
  margin: 4px auto 14px;

  @media only screen and (max-height: 800px) {
    display: none;
  }
`;

export const SkipButton = styled(ActionButton).attrs(() => ({
  variant: BUTTON_VARIANT.PRIMARY,
  bordered: true,
}))`
  margin-top: 12px;
  justify-content: center;

  && {
    margin-left: 0;
  }

  div {
    flex: initial;
  }
`;

export const SubmitButton = styled(ActionButton).attrs(() => ({
  variant: BUTTON_VARIANT.PRIMARY,
}))`
  justify-content: center;
  margin-top: 2px;

  div {
    flex: initial;
  }
`;

export const FormField = styled(FormFieldBase)`
  padding-bottom: 0;
  margin: 2px 0 20px;
`;

export const Form = styled.form`
  display: flex;
  flex-flow: column nowrap;
`;

import { COLOR } from '@bugbug/core/theme/colors';
import styled from 'styled-components';

import LogoSVG from '~/images/logo.svg?react';

export const Container = styled.div`
  background: ${COLOR.WHITE};
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 10;
  pointer-events: none;
`;

export const Logo = styled(LogoSVG)`
  height: 33px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

import brand24 from '~/images/brands/brand24.svg';
import innential from '~/images/brands/innential.svg';
import weglot from '~/images/brands/weglot.png';
import zahara from '~/images/brands/zahara.png';
import i18n from '~/translations';

export const BRAND24_LOGO = {
  logoUrl: brand24,
  alt: i18n.t('signUp.benefits.brands.brand24.alt', 'Brand24'),
};

export const BRANDS_LIST = [
  {
    logoUrl: weglot,
    alt: i18n.t('signUp.benefits.brands.weglot.alt', 'WeGlot'),
  },
  {
    logoUrl: zahara,
    alt: i18n.t('signUp.benefits.brands.zahara.alt', 'Zahara'),
  },
  {
    logoUrl: innential,
    alt: i18n.t('signUp.benefits.brands.innential.alt', 'Innential'),
  },
];

export const BENEFITS_LIST = [
  {
    label: i18n.t('signUp.benefits.list.unlimitedLocalTestRuns', 'Unlimited local test runs'),
  },
  {
    label: i18n.t('signUp.benefits.list.unlimitedUserSeats', 'Unlimited user seats'),
  },
  {
    label: i18n.t('signUp.benefits.list.simpleButPowerful', 'Simple but powerful'),
  },
];

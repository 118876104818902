import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import ErrorPage from '~/components/ErrorPage';
import urls from '~/views/urls';

import NotFoundImage from '~/images/404.svg?react';

const NotFound = ({ message, buttonLabel, onButtonClick }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleClick = (event) => {
    if (onButtonClick) {
      onButtonClick(event);
    } else {
      history.push(urls.home);
    }
  };

  return (
    <>
      <Helmet title={t('notFound.pageTitle', 'Not found')} />
      <ErrorPage
        Image={NotFoundImage}
        message={
          <>
            <p>{message || t('notFound.default.message.details', 'This page does not exist.')}</p>
            <p>
              {t('notFound.default.message.generic', 'Check if you entered a correct URL address.')}
            </p>
          </>
        }
        buttonLabel={buttonLabel || t('notFound.default.buttonLabel', 'Go to homepage')}
        onButtonClick={handleClick}
      />
    </>
  );
};

NotFound.propTypes = {
  message: PropTypes.string,
  buttonLabel: PropTypes.string,
  onButtonClick: PropTypes.func,
};

NotFound.defaultProps = {
  message: '',
  buttonLabel: '',
  onButtonClick: null,
};

export default NotFound;

import { ActionButton } from '@bugbug/core/components/Button';
import { ErrorMessage as ErrorMessageBase } from '@bugbug/core/theme/typography';
import styled from 'styled-components';

import FormFieldComponent from '~/components/FormField';

export const Form = styled.form`
  display: flex;
  flex-flow: column nowrap;
`;

export const SubmitButton = styled(ActionButton)`
  margin-top: 12px;
  justify-content: center;

  div {
    flex: initial;
  }
`;

export const FormField = styled(FormFieldComponent)`
  padding-bottom: 0;
  margin: 4px 0;
`;

export const ErrorMessage = styled(ErrorMessageBase)`
  a {
    text-decoration: underline;
    color: inherit;
  }
`;
